import { IconBaseProps } from '../../types/types'

interface Props extends IconBaseProps {}

function QuestionMark({ className, onClick }: Props) {
	return (
		<div
			className={`${onClick ? 'cursor-pointer hover:scale-110 hover:rotate-3 transition-all duration-300 ' : ' '}` + className}
			onClick={onClick}>
			<svg id='help_center_black_24dp' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
				<rect id='Rectangle_527' data-name='Rectangle 527' width='24' height='24' fill='none' />
				<path
					id='Path_954'
					data-name='Path 954'
					d='M13.25,16.74A1.24,1.24,0,0,1,12,18a1.255,1.255,0,0,1-1.26-1.26,1.255,1.255,0,0,1,2.51,0ZM11.99,6A3.562,3.562,0,0,0,8.56,8.49l1.64.69A1.912,1.912,0,0,1,12,7.7a1.485,1.485,0,0,1,1.37,2.33c-.54.77-1.47,1.29-1.96,2.16a3.811,3.811,0,0,0-.31,1.98h1.82a2.524,2.524,0,0,1,.22-1.41c.39-.72,1.11-1.06,1.87-2.17a3.019,3.019,0,0,0-.02-3.08A3.359,3.359,0,0,0,11.99,6ZM19,5H5V19H19V5m0-2a2.006,2.006,0,0,1,2,2V19a2.006,2.006,0,0,1-2,2H5a2.006,2.006,0,0,1-2-2V5A2.006,2.006,0,0,1,5,3Z'
					fill='#101820'
				/>
			</svg>
		</div>
	)
}

export default QuestionMark
