import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../assets/icons/logo";
import PaymentField from "../components/payment-field";
import PlanCard from "../components/plan-card";
import { PaymentMethod } from "@stripe/stripe-js";
import Button from "../components/core/button";
import StripeService from "../services/stripe";
import errorPopupParser from "../utils/error-popup-parser";
import useGlobalState from "../utils/use-global-state";
import refreshUserData from "../utils/refresh-user-data";
import asyncTimeout from "../utils/async-timeout";
import PriceService from "../services/price";
import { StripePlan } from "../types/stripe-types";

function Subscribe() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const stripe = new StripeService();
  const [plans, setPlans] = useState<StripePlan[] | undefined>(undefined);

  const { dispatch } = useGlobalState();

  const [activePlan, setActivePlan] = useState<StripePlan | undefined>(
    undefined
  );

  const getStripePlans = async () => {
    try {
      const pricesResponse = await PriceService.getPrices();
      setPlans(pricesResponse);
      setActivePlan(pricesResponse[0]);
      return pricesResponse;
    } catch (error) {
      errorPopupParser(error, dispatch);
    }
  };
  const [paymentMethodId, setPaymentMethodId] = useState<PaymentMethod>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getStripePlans();
  }, []);

  const renderSubscriptions = () => {
    if (!plans) return;
    return plans.map((plan, index) => {
      return (
        <PlanCard
          plan={plan}
          key={index}
          isActive={activePlan === plan}
          setIsActive={() => setActivePlan(plan)}
        />
      );
    });
  };

  const addPaymentMethod = async (paymentMethodId: PaymentMethod) => {
    try {
      const result = await stripe.addPaymentMethod(paymentMethodId.id);
      return result;
    } catch (error) {
      throw error;
    }
  };

  const createSubscription = async () => {
    if (!activePlan) return;
    try {
      let currentCurrency = "usd";
      await PriceService.getCurrency()
        .then((currency) => {
          currentCurrency = currency;
        })
        .catch((error) => {
          console.error(error);
        });
      const result = await Promise.all([
        stripe.createSubscription(activePlan?.id, currentCurrency),
        asyncTimeout(1000),
      ]);
      return result;
    } catch (error) {
      throw error;
    }
  };

  const subscribeUser = async () => {
    setIsLoading(true);

    try {
      if (!activePlan || !paymentMethodId) return;
      const paymentMethodAttachResult = await addPaymentMethod(paymentMethodId);

      if (paymentMethodAttachResult !== 201) return;

      await createSubscription();

      dispatch({
        type: "setSnack",
        data: {
          isOpen: true,
          message: "Subscription successful!",
          severity: "success",
        },
      });

      setTimeout(() => {
        navigate("/complete");
        refreshUserData(dispatch);
      }, 1000);
    } catch (error) {
      errorPopupParser(error, dispatch);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-4">
      <div className="w-full flex justify-center items-center mb-4">
        <Logo className="mx-auto w-fit my-4 " />
      </div>

      <h2 className="text-[20px] font-bold text-grey-base font-chakraPetch">
        SUBSCRIBE
      </h2>
      <h1 className="text-[36px] font-bold leading-10 font-chakraPetch">
        SELECT YOUR PLAN
      </h1>

      <p className="font-roboto text-[14px] mb-4 text-grey-text-base">
        Our premium HTK experience allows you to access all of what HTK has to
        offer, for a smaller price. This includes:
      </p>

      <div className="mb-6">{renderSubscriptions()}</div>

      <h2 className="text-[20px] font-bold text-grey-base font-chakraPetch">
        SUBSCRIBE
      </h2>
      <h1 className="text-[36px] font-bold leading-10 font-chakraPetch">
        BILLING DETAILS
      </h1>

      <p className="font-roboto text-[14px] mb-4 text-grey-text-base">
        Add in a valid payment method to complete your plan setup.
      </p>

      <PaymentField setPaymentMethodId={setPaymentMethodId} />

      <Button
        text="FINISH"
        onClick={subscribeUser}
        className="mb-6"
        isLoading={isLoading}
      />
    </div>
  );
}

export default Subscribe;
