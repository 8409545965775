import { ChangeEvent, useRef } from 'react'
import Spinner from '../assets/icons/spinner'
import Upload from '../assets/icons/upload'

type Props = {
	onAvatarChange: (e: ChangeEvent<HTMLInputElement>) => void
	avatarPreview: string
	isUploading: boolean
}

function AvatarPicker({ onAvatarChange, avatarPreview, isUploading }: Props) {
	const inputFile = useRef<HTMLInputElement>(null)

	const clickImagePicker = () => {
		if (!inputFile?.current) return
		inputFile.current.click()
	}

	const renderAvatar = () => {
		if (isUploading) return <Spinner />
		else if (avatarPreview) return <img src={avatarPreview} className='h-full w-full rounded-[50%] object-cover' />
		else return <Upload />
	}

	return (
		<div>
			<input type='file' id='file' ref={inputFile} accept='image/*' style={{ display: 'none' }} onChange={(e) => onAvatarChange(e)} />

			<div
				onClick={() => clickImagePicker()}
				className={`w-[140px] h-[140px] min-h-[140px] flex items-center justify-center mx-auto relative bg-purple-light rounded-[50%] mb-4 cursor-pointer ${
					!avatarPreview && 'hover:scale-105 hover:shadow-lg transition-all duration-300 bg-grey-light'
				} border-[1px] border-grey-base`}>
				{renderAvatar()}
			</div>

			<p className='text-orange-base text-center cursor-pointer mb-8' onClick={() => clickImagePicker()}>
				UPLOAD PROFILE PICTURE
			</p>
		</div>
	)
}

export default AvatarPicker
