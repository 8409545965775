import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Arrow from '../assets/icons/arrow'
import Logo from '../assets/icons/logo'
import { CancelPages } from '../pages/cancel-plan'
import StripeService from '../services/stripe'
import asyncTimeout from '../utils/async-timeout'
import errorPopupParser from '../utils/error-popup-parser'
import useGlobalState from '../utils/use-global-state'
import Button from './core/button'

type Props = {
	setPage: (page: CancelPages) => void
}

const feedback = ['Too expensive', 'Not enough programs', 'Not what I am looking for', "Didn't get the results I was looking for", 'Other']

function CancelSubscriptionFeedback({ setPage }: Props) {
	const [feedbackList, setFeedbackList] = useState<string[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const stripe = new StripeService()

	const navigate = useNavigate()

	const { dispatch } = useGlobalState()

	const addOrRemoveItemFromList = (item: string) => {
		if (feedbackList.includes(item)) {
			const newList = feedbackList.filter((feedbackItem) => feedbackItem !== item)
			setFeedbackList(newList)
		} else {
			setFeedbackList([...feedbackList, item])
		}
	}

	const openPopupSuccessAndRedirect = () => {
		dispatch({
			type: 'setSnack',
			data: {
				isOpen: true,
				severity: 'success',
				message: 'Your subscription has been canceled, thank you for your feedback!',
			},
		})

		setTimeout(() => {
			navigate('/subscription-settings')
		}, 1000)
	}

	const cancelAndSubmitFeedback = async () => {
		setIsLoading(true)
		try {
			await stripe.deleteSubscription()

			await Promise.all([stripe.createCancellationFeedback(feedbackList), asyncTimeout(1000)])

			openPopupSuccessAndRedirect()
		} catch (error) {
			if (error instanceof Error && error.message === 'Error sending cancellation feedback') openPopupSuccessAndRedirect()
			else errorPopupParser(error, dispatch)
		} finally {
			setIsLoading(false)
		}
	}

	const renderFeedbackList = () => {
		return feedback.map((feedbackItem, index) => {
			return (
				<label className='flex items-center mb-6 gap-4' key={index}>
					<input
						type='checkbox'
						className='h-5 w-5 border-orange-base bg-white text-orange-base focus:ring-white cursor-pointer'
						checked={feedbackList.includes(feedbackItem)}
						onChange={() => addOrRemoveItemFromList(feedbackItem)}
					/>
					<span className='text-[16px] text-grey-text-base opacity-80 leading-4'>{feedbackItem}</span>
				</label>
			)
		})
	}

	return (
		<>
			<div className='flex gap-4 items-center p-4 mb-4'>
				<Arrow onClick={() => setPage('Warning')} />
				<Logo className='mx-auto w-fit ' />
			</div>

			<div className='px-4 mt-10'>
				<h2 className='text-[20px] font-bold text-grey-base font-chakraPetch'>CANCEL SUBSCRIPTION</h2>
				<h1 className='text-[36px] font-bold leading-10 font-chakraPetch mb-2'>WE WOULD LOVE TO HEAR FROM YOU</h1>
				<p className='text-[14px] text-grey-text-base opacity-80 leading-4 mb-6'>
					We hate to see you go, but before you do could you let us know why? Select from the options below as to why you are unsubscribing from
					HTK.
				</p>

				{renderFeedbackList()}
			</div>

			<div className='px-4 mt-40'>
				<Button text='SUBMIT FEEDBACK' onClick={cancelAndSubmitFeedback} isLoading={isLoading} />
			</div>
		</>
	)
}

export default CancelSubscriptionFeedback
