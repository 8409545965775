import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Arrow from "../assets/icons/arrow";
import Logo from "../assets/icons/logo";
import Settings from "../assets/icons/settings";
import Button from "../components/core/button";
import PlanCard from "../components/plan-card";
import { PriceMetaData } from "../components/subscription-card";

import StripeService from "../services/stripe";
import {
  PaymentMethodResult,
  StripeSubscription,
  SubscriptionPlan,
} from "../types/types";
import asyncTimeout from "../utils/async-timeout";
import errorPopupParser from "../utils/error-popup-parser";
import findActiveSubscription from "../utils/find-active-subscription";
import useGlobalState from "../utils/use-global-state";
import Loading from "./loading";
import PriceService from "../services/price";
import { StripePlan } from "../types/stripe-types";

function UpdatePlan() {
  const navigate = useNavigate();

  const [activePlan, setActivePlan] = useState<StripePlan | undefined>(
    undefined
  );
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodResult>();
  const [activeSubscription, setActiveSubscription] =
    useState<StripeSubscription>();

  const { state, dispatch } = useGlobalState();
  const { user } = state;
  const [plans, setPlans] = useState<StripePlan[] | undefined>(undefined);

  const stripe = new StripeService();

  const getStripPlans = async () => {
    try {
      const pricesResponse = await PriceService.getPrices();
      setPlans(pricesResponse);

      return pricesResponse;
    } catch (error) {
      errorPopupParser(error, dispatch);
    }
  };

  const renderSubscriptions = () => {
    if (!plans) return;
    return plans.map((plan, index) => {
      return (
        <PlanCard
          plan={plan}
          key={index}
          isActive={activePlan === plan}
          setIsActive={() => setActivePlan(plan)}
        />
      );
    });
  };

  const getPaymentMethods = async () => {
    try {
      const paymentMethodsResult = await stripe.getPaymentMethods();
      console.log(
        "PAYMENTMETHODS",
        JSON.stringify(paymentMethodsResult, null, 3)
      );
      setPaymentMethods(paymentMethodsResult);
    } catch (error) {
      errorPopupParser(error, dispatch);
    }
  };

  const getStripeDetails = async () => {
    setIsInitialLoading(true);
    try {
      await Promise.all([
        getSubscription(),
        getPaymentMethods(),
        asyncTimeout(900),
      ]);
    } catch (error) {
      errorPopupParser(error, dispatch);
    } finally {
      setIsInitialLoading(false);
    }
  };

  const updateUserSubscription = async () => {
    if (!activePlan?.id) return;

    setIsLoading(true);
    try {
      let currentCurrency = "usd";
      await PriceService.getCurrency()
        .then((currency) => {
          currentCurrency = currency;
        })
        .catch((error) => {
          console.error(error);
        });
      await stripe.createSubscription(activePlan.id, currentCurrency);

      dispatch({
        type: "setSnack",
        data: {
          isOpen: true,
          severity: "success",
          message: "Your plan has been updated!",
        },
      });

      setTimeout(() => {
        navigate("/subscription-settings");
      }, 1000);
    } catch (error) {
      errorPopupParser(error, dispatch);
    } finally {
      setIsLoading(false);
    }
  };

  const renderPaymentMethod = () => {
    if (isInitialLoading)
      return (
        <div className="p-8 bg-grey-light flex justify-between animate-pulse" />
      );
    if (!paymentMethods?.defaultPaymentMethod)
      return (
        <div className="p-6 bg-grey-light flex justify-between">
          No payment method found{" "}
          <Settings onClick={() => navigate("/update-payment-method")} />
        </div>
      );
    return (
      <div
        className="p-2 bg-grey-light flex justify-between items-center cursor-pointer"
        onClick={() => navigate("/update-payment-method")}
      >
        <div>
          <p className="capitalize text-[14px]">
            {paymentMethods.defaultPaymentMethod.type}
          </p>
          <p className="text-[14px] font-bold">
            XXXX XXXX XXXX {paymentMethods.defaultPaymentMethod.card?.last4}
          </p>
        </div>

        <Settings />
      </div>
    );
  };

  const getSubscription = async () => {
    try {
      const subscriptionsResult = await stripe.getSubscriptions();

      console.log(subscriptionsResult);
      // which plan the user is currently on
      const activeSubscription = findActiveSubscription(subscriptionsResult);

      console.log(activeSubscription);

      if (!activeSubscription) return;

      setActiveSubscription(activeSubscription);

      // find the plan from our constants that aligns with the subscription the user has in stripe, based on period metadata
      if (!plans) return;
      const activePlan = plans.find(
        (plan) => plan.recurring.interval === activeSubscription.plan.interval
      );

      setActivePlan(activePlan);
    } catch (error) {
      errorPopupParser(error, dispatch);
    }
  };

  const findIfPlanHasChanged = (): boolean => {
    if (!activeSubscription) return false;

    if (activePlan?.recurring.interval === activeSubscription.plan.interval)
      return true;

    return false;
  };

  useEffect(() => {
    getStripeDetails();
  }, []);

  if (isInitialLoading) return <Loading />;

  return (
    <div className="px-4">
      <div className="w-full flex justify-between items-center mb-4">
        <Arrow onClick={() => navigate(-1)} />
        <Logo className="w-fit my-4 " />
      </div>

      <h2 className="text-[20px] font-bold text-grey-base font-chakraPetch">
        {user?.userSubscription.productId !== "" ? "SWAP" : "SUBSCRIBE"}
      </h2>
      <h1 className="text-[36px] font-bold leading-10 font-chakraPetch">
        {user?.userSubscription.productId !== ""
          ? "UPDATE PLAN"
          : "SELECT YOUR PLAN"}
      </h1>

      <p className="font-roboto text-[14px] mb-4 text-grey-text-base opacity-80 leading-4">
        To get you set up with your subscription add a valid billing method to
        your account.
      </p>

      {renderSubscriptions()}

      <div className=" mb-6">
        <p className="font-bold font-chakraPetch text-[20px]">PAYMENT METHOD</p>
        {renderPaymentMethod()}
      </div>

      <Button
        text="CONTINUE"
        onClick={updateUserSubscription}
        disabled={findIfPlanHasChanged()}
        isLoading={isLoading}
      />
    </div>
  );
}

export default UpdatePlan;
