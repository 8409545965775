import { useEffect, useState } from "react";
import { CardNumberElement, CardCvcElement, CardExpiryElement, useStripe, useElements } from "@stripe/react-stripe-js";
import TextInput from "./core/text-input";
import { PaymentMethod } from "@stripe/stripe-js";
import Spinner from "../assets/icons/spinner";

const CARD_ELEMENT_OPTIONS = {
	style: {
		base: {
			backgroundColor: "#F2F2F2",
			fontFamily: '"Roboto", sans-serif',
			fontSmoothing: "antialiased",
			fontWeight: "400",
			fontSize: "14px",

			"::placeholder": {
				color: "#000",
			},
		},
		invalid: {
			color: "#ED0000",
			iconColor: "#ED0000",
		},
	},
};

type PaymentResults = "SUCCESS" | "FAILED" | "IN PROGRESS";

type Props = {
	setPaymentMethodId: (pmId: PaymentMethod | undefined) => void;
	className?: string;
	shouldHideIndicators?: boolean;
};

function PaymentField({ setPaymentMethodId, className, shouldHideIndicators }: Props) {
	const [cardName, setCardName] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const [isNumberComplete, setIsNumberComplete] = useState(false);
	const [isCVCComplete, setIsCVCComplete] = useState(false);
	const [isExpiryComplete, setIsExpiryComplete] = useState(false);

	const [paymentResult, setPaymentResult] = useState<PaymentResults>("IN PROGRESS");

	const elements = useElements();
	const stripe = useStripe();

	const createPaymentMethod = async () => {
		if (!stripe || !elements || !cardName) return;

		const cardElement = elements.getElement(CardNumberElement);

		if (!cardElement) return;

		setIsLoading(true);
		setPaymentResult("IN PROGRESS");

		try {
			const result = await stripe.createPaymentMethod({
				type: "card",
				card: cardElement,
				billing_details: {
					name: cardName,
				},
			});

			setPaymentMethodId(result.paymentMethod);
		} catch (error) {
			console.log(error);
			setPaymentResult("FAILED");
			setPaymentMethodId(undefined);
		} finally {
			setPaymentResult("SUCCESS");
			setTimeout(() => {
				setIsLoading(false);
			}, 1200);
		}
	};

	const renderLoadingIndicators = (): JSX.Element => {
		if (paymentResult === "SUCCESS") return <div className="flex items-center gap-2">Successfully processed payment method</div>;
		else if (paymentResult === "FAILED") return <div className="flex items-center gap-2">Invalid payment details</div>;
		else return <Spinner />;
	};

	useEffect(() => {
		if (cardName && isNumberComplete && isCVCComplete && isExpiryComplete) createPaymentMethod();
		else setPaymentMethodId(undefined);
	}, [cardName, isNumberComplete, isCVCComplete, isExpiryComplete]);

	return (
		<div className={`${className}`}>
			<TextInput placeholder="NAME ON CARD" value={cardName} onChange={(text) => setCardName(text)} />

			<CardNumberElement className="input-primary font-roboto" options={CARD_ELEMENT_OPTIONS} onChange={(e) => setIsNumberComplete(e.complete)} />
			<div className="flex gap-2 w-full mb-4">
				<CardCvcElement className="input-primary w-[40%] font-roboto" options={CARD_ELEMENT_OPTIONS} onChange={(e) => setIsCVCComplete(e.complete)} />

				<CardExpiryElement className="input-primary w-[40%] font-roboto" options={CARD_ELEMENT_OPTIONS} onChange={(e) => setIsExpiryComplete(e.complete)} />
			</div>
			{!shouldHideIndicators && (
				<div className={`${isLoading ? "opacity-100" : "opacity-0"} transition-all duration-300 `}>
					<div className="mb-4">{renderLoadingIndicators()} </div>
				</div>
			)}
		</div>
	);
}

export default PaymentField;
