import { AnimatePresence, motion } from "framer-motion";
import React, { ReactNode } from "react";

type Props = {
	children: ReactNode;
	index: number;
	mode?: "wait" | "sync" | "popLayout";
	className?: string;
	customDelay?: number;
};

const ComponentAnimator = ({ index, mode, className, children, customDelay }: Props) => {
	const detailColumnAnimation = {
		initial: "hidden",
		animate: "visible",
		variants: {
			hidden: { opacity: 0, y: 15 },
			visible: { opacity: 1, y: 0 },
		},
	};
	return (
		<AnimatePresence key={index} mode={mode ?? "sync"}>
			<motion.div
				{...detailColumnAnimation}
				transition={{
					delay: customDelay ?? index * 0.1,
					ease: "easeInOut",
				}}
				className={`h-full ${className}`}
			>
				{children}
			</motion.div>
		</AnimatePresence>
	);
};

export default ComponentAnimator;
