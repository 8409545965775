import { IconBaseProps } from '../../types/types'

interface Props extends IconBaseProps {}

function Ribbon({ className, onClick }: Props) {
	return (
		<div
			className={`${onClick ? 'cursor-pointer hover:scale-110 hover:rotate-3 transition-all duration-300 ' : ' '}` + className}
			onClick={onClick}>
			<svg id='workspace_premium_black_24dp' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
				<rect id='Rectangle_825' data-name='Rectangle 825' width='24' height='24' fill='none' />
				<path
					id='Path_1098'
					data-name='Path 1098'
					d='M9.68,13.69,12,11.93l2.31,1.76-.88-2.85L15.75,9H12.91L12,6.19,11.09,9H8.25l2.31,1.84ZM20,10A8,8,0,1,0,6,15.28V23l6-2,6,2V15.28A7.961,7.961,0,0,0,20,10ZM12,4a6,6,0,1,1-6,6A6,6,0,0,1,12,4Zm0,15L8,20.02v-3.1a7.947,7.947,0,0,0,8,0v3.1Z'
					fill='#fff'
				/>
			</svg>
		</div>
	)
}

export default Ribbon
