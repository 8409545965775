import { useFormik } from 'formik'
import { useState } from 'react'
import { RetrievePasswordPages } from '../pages/retrieve-password'
import AuthService from '../services/auth'
import asyncTimeout from '../utils/async-timeout'
import errorPopupParser from '../utils/error-popup-parser'
import useGlobalState from '../utils/use-global-state'
import { retrievePasswordSchema } from '../utils/validation-schemas'
import Button from './core/button'
import ValidationInput from './core/validation-input'

type Props = {
	setPage: (page: RetrievePasswordPages) => void
	setEmail: (email: string) => void
}

function RetrieveEnterEmail({ setPage, setEmail }: Props) {
	const auth = new AuthService()
	const { dispatch } = useGlobalState()

	const [isLoading, setIsLoading] = useState(false)

	const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
		initialValues: {
			email: '',
		},
		onSubmit: (values) => {
			sendVerificationCode()
		},
		validationSchema: retrievePasswordSchema,
	})

	const sendVerificationCode = async () => {
		setIsLoading(true)
		try {
			await Promise.all([auth.sendVerificationCode(values.email), asyncTimeout(800)])

			setPage('ENTER_CODE')
			setEmail(values.email)
		} catch (error) {
			errorPopupParser(error, dispatch)
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<div>
			<h2 className='text-[20px] font-bold text-grey-base font-chakraPetch'>RETRIEVE ACCOUNT</h2>
			<h1 className='text-[36px] font-bold leading-10 font-chakraPetch'>EMAIL</h1>

			<p className='font-roboto text-[14px] mb-4 text-grey-text-base opacity-80 leading-4'>
				To retrieve your account enter the email associated with your HTK account. We will send you a 6 digit code to verify yourself.
			</p>

			<ValidationInput
				value={values.email}
				error={errors.email}
				touched={touched.email}
				onChange={(e) => setFieldValue('email', e.target.value)}
				placeholder='EMAIL'
			/>

			<Button text='NEXT' onClick={handleSubmit} className='mt-12' isLoading={isLoading} />
		</div>
	)
}

export default RetrieveEnterEmail
