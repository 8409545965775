import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PaymentField from "../../components/payment-field";
import { PaymentMethod } from "@stripe/stripe-js";
import Button from "../../components/core/button";
import errorPopupParser from "../../utils/error-popup-parser";
import useGlobalState from "../../utils/use-global-state";
import StripeService from "../../services/stripe";
import asyncTimeout from "../../utils/async-timeout";
import { SubscriptionPlan } from "../../types/types";
import AuthService from "../../services/auth";
import { useSignupStore } from "../../utils/store/signup-store";
import { InitialUserState } from "../../utils/store/initial-states";
import { StripePlan } from "../../types/stripe-types";
import axios from "axios";
import PriceService from "../../services/price";

type Props = {
  selectedPlan: StripePlan;
  isDisabled: boolean;
  className?: string;
};

function BillingDetails({ selectedPlan, isDisabled, className }: Props) {
  const navigate = useNavigate();

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
  const [currentPaymentMethod, setCurrentPaymentMethod] =
    useState<PaymentMethod>();
  const [isLoading, setIsLoading] = useState(false);
  const { setUser } = useSignupStore();
  const stripe = new StripeService();

  const { dispatch } = useGlobalState();

  const deleteOldPaymentMethod = async () => {
    if (!currentPaymentMethod) return;
    const deleteResult = await stripe.deletePaymentMethod(
      currentPaymentMethod.id
    );
    console.log(deleteResult);
  };

  const createPaymentMethod = async (paymentMethod: PaymentMethod) => {
    try {
      const result = await stripe.addPaymentMethod(paymentMethod.id);
      return result;
    } catch (error) {
      throw error;
    }
  };

  const createSubscription = async () => {
    setIsLoading(true);
    try {
      await deleteOldPaymentMethod();
      if (!paymentMethod) return;
      await Promise.all([
        createPaymentMethod(paymentMethod),
        asyncTimeout(1000),
      ]);
      let currentCurrency = "usd";
      await PriceService.getCurrency()
        .then((currency) => {
          currentCurrency = currency;
        })
        .catch((error) => {
          console.error(error);
        });
      console.log(currentCurrency);
      await stripe.createSubscription(selectedPlan.id, currentCurrency);
      dispatch({
        type: "setSnack",
        data: {
          isOpen: true,
          message: "Thank you for your subscription.",
          severity: "success",
        },
      });
      await getUserData();
      setTimeout(() => {
        navigate("/home");
      }, 3000);
    } catch (error) {
      console.log("Error while subscribing", { error });
      errorPopupParser(error, dispatch);
    } finally {
      setIsLoading(false);
    }
  };

  const getUserData = async () => {
    try {
      const [userData] = await Promise.all([
        AuthService.getMe(),
        asyncTimeout(1000),
      ]);

      dispatch({ type: "setUser", data: userData });
      setUser({ ...InitialUserState });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={` ${className}`}>
      <p className={`text-[24px] font-bold leading-6 font-chakraPetch`}>
        BILLING DETAILS
      </p>
      <p className="font-roboto text-[14px] mb-4 text-grey-text-base opacity-80">
        Add in a valid payment method to complete your plan setup.
      </p>
      <PaymentField
        setPaymentMethodId={setPaymentMethod}
        shouldHideIndicators
      />
      <Button
        disabled={isDisabled}
        text="PURCHASE"
        onClick={createSubscription}
        isLoading={isLoading}
      />
    </div>
  );
}

export default BillingDetails;
