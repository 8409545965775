import { IconBaseProps } from '../../types/types'

interface Props extends IconBaseProps {}

function Upload({ className, onClick }: Props) {
	return (
		<div
			className={`${onClick ? 'cursor-pointer hover:scale-110 hover:rotate-3 transition-all duration-300 ' : ' '}` + className}
			onClick={onClick}>
			<svg xmlns='http://www.w3.org/2000/svg' width='46.061' height='46.061' viewBox='0 0 46.061 46.061'>
				<g id='logout_black_24dp' transform='translate(0 46.061) rotate(-90)'>
					<g id='Group_309' data-name='Group 309'>
						<path id='Path_925' data-name='Path 925' d='M0,0H46.061V46.061H0Z' fill='none' />
					</g>
					<g id='Group_310' data-name='Group 310' transform='translate(5.758 5.758)'>
						<path
							id='Path_926'
							data-name='Path 926'
							d='M29.869,12.6,27.163,15.3,30.2,18.354H14.515v3.838H30.2l-3.032,3.032,2.706,2.725,7.677-7.677ZM6.838,6.838H20.273V3H6.838A3.85,3.85,0,0,0,3,6.838V33.707a3.85,3.85,0,0,0,3.838,3.838H20.273V33.707H6.838Z'
							transform='translate(-3 -3)'
							fill='#fff'
						/>
					</g>
				</g>
			</svg>
		</div>
	)
}

export default Upload
