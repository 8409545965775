import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Arrow from "../assets/icons/arrow";
import AuthService from "../services/auth";
import asyncTimeout from "../utils/async-timeout";
import errorPopupParser from "../utils/error-popup-parser";
import useGlobalState from "../utils/use-global-state";
import Button from "./core/button";
import ModalWrapper from "./core/modal-wrapper";
import TextInput from "./core/text-input";

type Props = {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
};

const lostData = ["All HTK Training Programs", "Special Limited Time Programs", "HTK Fitness Challenges", "All Logged and tracked data attached to your account", "Past Program data"];

function DeleteAccountModal({ isOpen, setIsOpen }: Props) {
	const { dispatch, state } = useGlobalState();
	const auth = new AuthService();
	const navigate = useNavigate();

	const [confirmationText, setConfirmationText] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const deleteUserAccount = async () => {
		if (confirmationText !== "Delete Account" || !state.user) return;
		setIsLoading(true);
		try {
			await Promise.all([auth.deleteOne(state.user.id), asyncTimeout(3000)]);
			localStorage.removeItem("HTKToken");
			dispatch({ type: "setUser", data: undefined });
			navigate("/signup-with-plan");
		} catch (error) {
			errorPopupParser(error, dispatch);
		} finally {
			setIsLoading(false);
		}
	};

	const renderDataLostList = () => {
		return (
			<ul className="text-[14px] text-grey-text-base opacity-80 leading-6 mb-6 ml-10">
				{lostData.map((data, index) => {
					return <li key={index}>{data}</li>;
				})}
			</ul>
		);
	};

	return (
		<ModalWrapper isOpen={isOpen} setIsOpen={setIsOpen}>
			<div className="absolute bottom-0 bg-white " onClick={(e) => e.stopPropagation()}>
				<div className="flex items-center p-3 border-b border-grey-light font-bold gap-4">
					<Arrow onClick={() => setIsOpen(false)} />
					<div className="text-[20px] text-black-base font-chakraPetch">DELETE ACCOUNT</div>
				</div>

				<div className="p-3 text-[14px] text-grey-text-base opacity-80 leading-4">You will not be able to access your HTK account and all data will be lost permanently lost to you.</div>

				<div className="p-3 text-[14px] text-grey-text-base opacity-80 leading-4">If you have a current subscription with HTK the subscription will be cancelled upon account deletion.</div>

				<div className="p-3 text-[14px] text-grey-text-base opacity-80 leading-4">You will lose your data accosted with your account such as:</div>

				{renderDataLostList()}

				<div className="text-black-base text-[20px] font-semibold flex justify-between mx-3 font-chakraPetch">CONFIRM DELETE ACCOUNT</div>

				<div className="px-3 text-[14px] text-grey-text-base opacity-80 leading-4 mb-2">
					To confirm your account deletion, type in the field below <span className="text-error-base">“Delete Account”</span> to finalise deletion.
				</div>

				<div className="mx-3 mb-4">
					<TextInput placeholder="Delete Account" value={confirmationText} onChange={(value) => setConfirmationText(value)} />
				</div>

				<div className="mx-3 mb-8">
					<Button text="Delete Account" isLoading={isLoading} onClick={deleteUserAccount} />
				</div>
			</div>
		</ModalWrapper>
	);
}

export default DeleteAccountModal;
