import React from "react";

type Props = {
	className?: string;
	labelClassName?: string;
	label?: string;
	isChecked: boolean;
	onCheckedChange: (value: boolean) => void;
};
const CheckboxContainer = ({ className, labelClassName, label, isChecked, onCheckedChange }: Props) => {
	return (
		<div className={`flex items-center gap-1  ${className}`}>
			<input className={`text-orange-base focus:outline-orange-base border-orange-base`} type={"checkbox"} checked={isChecked} onChange={(e) => onCheckedChange(e.target.checked)} />
			<label className={`font-roboto text-[14px]  text-grey-text-base/80 ${labelClassName}`}>{label}</label>
		</div>
	);
};

export default CheckboxContainer;
