import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/core/button";

function Complete() {
	const navigate = useNavigate();

	return (
		<div className="bg-complete-bg h-screen bg-no-repeat bg-cover p-4 flex flex-col justify-end text-center">
			<h2 className="text-[20px] font-bold text-white opacity-40 font-chakraPetch">SIGN UP COMPLETE!</h2>
			<h1 className="text-[36px] font-bold leading-10 font-chakraPetch text-white mb-6">READY TO ROLL!</h1>

			<p className="font-roboto text-[14px] mb-8 text-white">You are all set to go! You can now download and sign into the HTK mobile app!</p>

			<a className={`btn-primary mb-4`} target="_blank" href={process.env.REACT_APP_APPLE_STORE}>
				DOWNLOAD ON THE APP STORE
			</a>
			<a className={`btn-primary mb-4`} target="_blank" href={process.env.REACT_APP_PLAY_STORE}>
				DOWNLOAD ON THE PLAY STORE
			</a>

			<p className="my-8 text-white cursor-pointer " onClick={() => navigate("/")}>
				PROCEED TO ACCOUNT SETTINGS
			</p>
		</div>
	);
}

export default Complete;
