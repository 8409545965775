import React from "react";
import { StripeSubscription } from "../types/types";
import moment from "moment";

type Props = {
  subscription: StripeSubscription | undefined;
};

export type PriceMetaData = {
  period: "month" | "year";
};

function SubscriptionCard({ subscription }: Props) {
  if (!subscription)
    return (
      <div className="font-roboto text-[14px] mb-1 text-grey-text-base">
        You are not currently subscribed, click below to choose your plan.
      </div>
    );

  const getSubscriptionPrice = () => {
    if (!subscription.plan.amount) return "--";
    else return "$" + subscription.plan.amount / 100;
  };

  const getInterval = () => {
    if (subscription.plan.interval === "year") return "Annually";
    return "Monthly";
  };

  const getNextPaymentInfo = () => {
    if (subscription.cancel_at_period_end)
      return (
        <p>
          Plan ending on:{" "}
          <span className="font-bold">
            {moment
              .unix(subscription.billing_cycle_anchor)
              .format("DD-MM-yyyy")}
          </span>
        </p>
      );

    if (subscription.trial_end)
      return (
        <p>
          Trial ends:{" "}
          <span className="font-bold">
            {moment
              .unix(subscription.billing_cycle_anchor)
              .format("DD-MM-yyyy")}
          </span>
        </p>
      );

    return (
      <p>
        Next Payment:{" "}
        <span className="font-bold">
          {moment.unix(subscription.billing_cycle_anchor).format("DD-MM-yyyy")}
        </span>
      </p>
    );
  };

  return (
    <div>
      <div className="bg-[#EE5340CC] p-2 mb-2 ">
        <div className="flex gap-4 font-chakraPetch mb-1">
          <p className="text-white font-semibold text-[24px]">
            {getSubscriptionPrice()}
          </p>
          <p className="text-white font-semibold text-[24px] opacity-80 uppercase">
            {getInterval()}
          </p>
          {getInterval() === "Annually" && (
            <p className="ml-auto bg-white flex items-center px-3 font-bold text-grey-text-base">
              SAVE $119.89!
            </p>
          )}
        </div>
        <p className="text-white text-[14px] opacity-80 leading-4">
          {getInterval()} billed subscription with full access to all HTK
          training programs and challenges, at a discount!
        </p>
      </div>
      <div className="bg-grey-light p-2">{getNextPaymentInfo()}</div>
    </div>
  );
}

export default SubscriptionCard;
