import { create } from "zustand";
import { User } from "../../types/types";
import { InitialUserState } from "./initial-states";
export type SignupState = {
	user: User;
};
export type SignupAction = {
	setUser: (user: User) => void;
};

export type SignupStore = SignupState & SignupAction;

export const useSignupStore = create<SignupStore>((set) => ({
	user: InitialUserState,
	setUser: (user) => set(() => ({ user })),
}));
