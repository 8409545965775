import { IconBaseProps } from '../../types/types'

interface Props extends IconBaseProps {}

function Logout({ className, onClick }: Props) {
	return (
		<div
			className={`${onClick ? 'cursor-pointer hover:scale-110 hover:rotate-3 transition-all duration-300 ' : ' '}` + className}
			onClick={onClick}>
			<svg id='logout_black_24dp' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
				<g id='Group_309' data-name='Group 309'>
					<path id='Path_925' data-name='Path 925' d='M0,0H24V24H0Z' fill='none' />
				</g>
				<g id='Group_310' data-name='Group 310'>
					<path
						id='Path_926'
						data-name='Path 926'
						d='M17,8,15.59,9.41,17.17,11H9v2h8.17l-1.58,1.58L17,16l4-4ZM5,5h7V3H5A2.006,2.006,0,0,0,3,5V19a2.006,2.006,0,0,0,2,2h7V19H5Z'
					/>
				</g>
			</svg>
		</div>
	)
}

export default Logout
