import { IconBaseProps } from '../../types/types'

interface Props extends IconBaseProps {}

function NavArrow({ className, onClick }: Props) {
	return (
		<div
			className={`${onClick ? 'cursor-pointer hover:scale-110 hover:rotate-3 transition-all duration-300 ' : ' '}` + className}
			onClick={onClick}>
			<svg id='arrow_forward_black_24dp' xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'>
				<path id='Path_868' data-name='Path 868' d='M0,0H18V18H0Z' fill='none' />
				<path
					id='Path_869'
					data-name='Path 869'
					d='M10,4,8.943,5.057,13.128,9.25H4v1.5h9.128L8.943,14.943,10,16l6-6Z'
					transform='translate(-1 -1)'
					fill='#ee5340'
				/>
			</svg>
		</div>
	)
}

export default NavArrow
