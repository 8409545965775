import React from 'react'
import Logo from '../assets/icons/logo'

function Loading() {
	return (
		<div className='w-full h-full min-h-screen min-w-screen flex justify-center items-center'>
			<Logo className='mx-auto w-fit my-4 animate-pulse' height={100} width={120} />
		</div>
	)
}

export default Loading
