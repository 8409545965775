import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Arrow from "../assets/icons/arrow";
import Logo from "../assets/icons/logo";
import ShowPassword from "../assets/icons/show-password";
import Button from "../components/core/button";
import ValidationInput from "../components/core/validation-input";
import AuthService from "../services/auth";
import asyncTimeout from "../utils/async-timeout";
import errorPopupParser from "../utils/error-popup-parser";
import useGlobalState from "../utils/use-global-state";
import { updatePasswordSchema } from "../utils/validation-schemas";

export type UpdatePasswordPages = "AUTH" | "UPDATE_PASSWORD";

function UpdatePassword() {
	const navigate = useNavigate();
	const auth = new AuthService();
	const { state, dispatch } = useGlobalState();
	const { user } = state;

	const [currentPasswordType, setCurrentPasswordType] = useState<"password" | "text">("password");
	const [newPasswordType, setNewPasswordType] = useState<"password" | "text">("password");
	const [confirmNewPasswordType, setConfirmNewPasswordType] = useState<"password" | "text">("password");
	const [isLoading, setIsLoading] = useState(false);

	const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
		initialValues: {
			currentPassword: "",
			newPassword: "",
			confirmNewPassword: "",
		},
		onSubmit: (values) => {
			updateUserPassword();
		},
		validationSchema: updatePasswordSchema,
	});

	const updateUserPassword = async () => {
		setIsLoading(true);
		try {
			await Promise.all([auth.updatePassword(user!.id, values.currentPassword, values.newPassword), asyncTimeout(900)]);
			dispatch({
				type: "setSnack",
				data: {
					isOpen: true,
					message: "Your password has been updated!",
					severity: "success",
				},
			});
			navigate("/");
		} catch (error) {
			errorPopupParser(error, dispatch);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="px-4">
			<div className="w-full flex justify-between items-center mb-4">
				<Arrow onClick={() => navigate(-1)} />
				<Logo className="w-fit my-4 " />
			</div>

			<h2 className="text-[20px] font-bold text-grey-base font-chakraPetch">UPDATE PASSWORD</h2>
			<h1 className="text-[36px] font-bold leading-10 font-chakraPetch mb-2">CURRENT PASSWORD</h1>
			<p className="font-roboto text-[14px] leading-4 text-grey-text-base opacity-80 mb-4">To authenticate this change, enter the current password attached to your HTK account.</p>

			<ValidationInput
				value={values.currentPassword}
				error={errors.currentPassword}
				touched={touched.currentPassword}
				onChange={(e) => setFieldValue("currentPassword", e.target.value)}
				placeholder="CURRENT PASSWORD"
				inputType={currentPasswordType}
				icon={<ShowPassword onClick={() => setCurrentPasswordType(currentPasswordType === "password" ? "text" : "password")} />}
			/>

			<h2 className="text-[20px] font-bold text-grey-base font-chakraPetch mt-12">UPDATE PASSWORD</h2>
			<h1 className="text-[36px] font-bold leading-10 font-chakraPetch mb-2">NEW PASSWORD</h1>
			<p className="font-roboto text-[14px] leading-4 text-grey-text-base opacity-80 mb-4">Enter a new password for your HTK account.</p>

			<ValidationInput
				value={values.newPassword}
				error={errors.newPassword}
				touched={touched.newPassword}
				onChange={(e) => setFieldValue("newPassword", e.target.value)}
				placeholder="PASSWORD"
				inputType={newPasswordType}
				icon={<ShowPassword onClick={() => setNewPasswordType(newPasswordType === "password" ? "text" : "password")} />}
			/>

			<ValidationInput
				value={values.confirmNewPassword}
				error={errors.confirmNewPassword}
				touched={touched.confirmNewPassword}
				onChange={(e) => setFieldValue("confirmNewPassword", e.target.value)}
				placeholder="CONFIRM PASSWORD"
				inputType={confirmNewPasswordType}
				icon={<ShowPassword onClick={() => setConfirmNewPasswordType(confirmNewPasswordType === "password" ? "text" : "password")} />}
			/>

			<Button text="SET PASSWORD" onClick={handleSubmit} className="mt-20" isLoading={isLoading} />
		</div>
	);
}

export default UpdatePassword;
