import axios from "axios";
import { PaymentMethodResult, StripeSubscription } from "../types/types";
import Instance from "../utils/axios";

const networkErrorMessage =
  "There was an error with your connection, please try again";

class StripeService {
  addPaymentMethod = async (paymentMethodId: string): Promise<number> => {
    try {
      const result = await Instance.post("payment-methods", {
        paymentMethodId,
      });

      console.log(result.data);
      return result.status;
    } catch (error) {
      if (error && axios.isAxiosError(error)) {
        if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED")
          throw Error(networkErrorMessage);
      }

      throw Error("Invalid payment method");
    }
  };

  createSubscription = async (
    priceId: string,
    currency: string
  ): Promise<number> => {
    try {
      const result = await Instance.post(`subscriptions`, {
        priceId,
        currency,
      });

      console.log(result.data);
      return result.status;
    } catch (error) {
      if (error && axios.isAxiosError(error)) {
        if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED")
          throw Error(networkErrorMessage);
      }

      throw Error("Could not create subscription, please try again");
    }
  };

  getSubscriptions = async (): Promise<StripeSubscription[]> => {
    try {
      const result = await Instance.get(`subscriptions`);

      return result.data.data;
    } catch (error) {
      if (error && axios.isAxiosError(error)) {
        if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED")
          throw Error(networkErrorMessage);
      }

      throw Error("Failed to get your subscription details, please try again");
    }
  };

  getPaymentMethods = async (): Promise<PaymentMethodResult> => {
    try {
      const result = await Instance.get(`payment-methods`);

      return result.data;
    } catch (error) {
      if (error && axios.isAxiosError(error)) {
        if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED")
          throw Error(networkErrorMessage);
      }

      throw Error("Failed to get your subscription details, please try again");
    }
  };

  deletePaymentMethod = async (paymentMethodId: string): Promise<boolean> => {
    try {
      const result = await Instance.delete(`payment-methods`, {
        data: {
          paymentMethodId,
        },
      });
      console.log(result);

      return true;
    } catch (error) {
      if (error && axios.isAxiosError(error)) {
        if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED")
          throw Error(networkErrorMessage);
      }

      throw Error("Could not delete payment method");
    }
  };

  deleteSubscription = async (): Promise<boolean> => {
    try {
      const result = await Instance.delete(`subscriptions`);
      console.log(result);

      return true;
    } catch (error) {
      if (error && axios.isAxiosError(error)) {
        if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED")
          throw Error(networkErrorMessage);
      }

      throw Error("Could not delete subscription");
    }
  };

  createCancellationFeedback = async (reasons: string[]): Promise<boolean> => {
    try {
      const result = await Instance.post(
        `subscriptions/cancellation-feedback`,
        {
          reasons,
        }
      );
      console.log(result);

      return true;
    } catch (error) {
      if (error && axios.isAxiosError(error)) {
        if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED")
          throw Error(networkErrorMessage);
      }

      throw Error("Error sending cancellation feedback");
    }
  };
}

export default StripeService;
