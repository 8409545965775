import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/icons/logo";
import Logout from "../assets/icons/logout";
import NavArrow from "../assets/icons/nav-arrow";
import Ribbon from "../assets/icons/ribbon";
import Button from "../components/core/button";
import DeleteAccountModal from "../components/delete-account-modal";
import useGlobalState from "../utils/use-global-state";

type NavLink = {
	name: string;
	url: string;
	isDeleteAccount?: boolean;
};

const navLinks: NavLink[] = [
	{
		name: "ACCOUNT DETAILS",
		url: "/account-details",
	},
	{
		name: "SUBSCRIPTION",
		url: "/subscription-settings",
	},
	{
		name: "DELETE ACCOUNT",
		url: "/delete-account",
		isDeleteAccount: true,
	},
	{
		name: "UPDATE PASSWORD",
		url: "/update-password",
	},
];

function Home() {
	const { state, dispatch } = useGlobalState();
	const { user } = state;

	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

	const navigate = useNavigate();

	const logoutUser = () => {
		dispatch({ type: "setUser", data: undefined });
		localStorage.removeItem("HTKToken");
		navigate("/login");
	};

	const renderNavLinks = () => {
		return navLinks.map((link, index) => {
			return (
				<div className="border-b border-grey-base flex justify-between py-3 font-bold cursor-pointer font-chakraPetch text-[18px] items-center " key={index} onClick={() => (link.isDeleteAccount ? setIsDeleteModalOpen(true) : navigate(link.url))}>
					<p>{link.name}</p>
					<NavArrow />
				</div>
			);
		});
	};

	const renderSubscriptionBlock = () => {
		if (user?.userSubscription.hasFreeAccess)
			return (
				<div className="bg-orange-base p-2 flex gap-6">
					<Ribbon />
					<div className="text-white font-chakraPetch">
						<p className="text-[18px] opacity-60 font-semibold">HTK FREE ACCESS MEMBER</p>
						<p className="text-[14px] leading-4 font-roboto opacity-90">You have been given free access to the Hard To Kill app. You can't update your subscription settings in the app, but you can reach out to the Hard To Kill team if you need changes made.</p>
					</div>
				</div>
			);
		if (user?.userSubscription.productId === null || user?.userSubscription.productId === "")
			return (
				<div className="bg-grey-base p-2 flex gap-6">
					<Ribbon />
					<div className="text-white font-chakraPetch">
						<p className="text-[18px] opacity-60 font-semibold">UNSUBSCRIBED</p>
						<p className="text-[14px] leading-4 font-roboto opacity-90">You are not currently subscribed to HTK. You can manage your subscription in the subscription settings.</p>
					</div>
				</div>
			);

		return (
			<div className="bg-orange-base p-2 flex gap-6">
				<Ribbon />
				<div className="text-white font-chakraPetch">
					<p className="text-[18px] opacity-60 font-semibold">HTK PREMIUM MEMBER</p>
					<p className="text-[14px] leading-4 font-roboto opacity-90">You are currently subscribed to HTK. You can manage your subscription in the subscription settings.</p>
				</div>
			</div>
		);
	};

	return (
		<div className="overflow-hidden max-h-screen">
			<div className="border-b-2 border-[#BBBDC0]  flex justify-between items-center p-4 mb-4">
				<Logo />
				<p className="font-bold font-chakraPetch text-[20px]">ACCOUNT</p>
				<p></p>
			</div>

			<DeleteAccountModal isOpen={isDeleteModalOpen} setIsOpen={setIsDeleteModalOpen} />

			<div className="px-4 mb-4">
				<div className="bg-grey-light p-3 flex justify-between items-center">
					<div>
						<p className="text-[14px]">Account</p>
						<p className="text-[18px] font-bold font-chakraPetch uppercase ">
							{user?.firstName} {user?.lastName}
						</p>
					</div>
					<Logout onClick={() => logoutUser()} />
				</div>
			</div>

			<div className="px-4">{renderSubscriptionBlock()}</div>

			<div className="p-4">{renderNavLinks()}</div>

			<div className="p-4">
				<a className={`btn-primary mb-4`} target="_blank" href={process.env.REACT_APP_APPLE_STORE}>
					DOWNLOAD ON THE APP STORE
				</a>
				<a className={`btn-primary mb-4`} target="_blank" href={process.env.REACT_APP_PLAY_STORE}>
					DOWNLOAD ON THE PLAY STORE
				</a>
			</div>
		</div>
	);
}

export default Home;
