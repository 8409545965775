import axios from "axios";
import Instance from "../utils/axios";
import Stripe from "stripe";
import { StripePlan } from "../types/stripe-types";
import { Currency } from "../types/types";

namespace PriceService {
  export const getPrices = async (): Promise<StripePlan[]> => {
    try {
      const { data, status } = await Instance.get("prices");
      if (status !== 200) throw Error;
      return data.data;
    } catch (error) {
      if (error && axios.isAxiosError(error)) {
        if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED")
          throw Error("Network error.");
      }
      throw Error("Failed to get prices.");
    }
  };

  export async function getCurrency(): Promise<string> {
    return new Promise((resolve, reject) => {
      axios
        .get("https://ipapi.co/json/")
        .then((response) => {
          let data = response.data;
          const currency = data.currency as String;
          if (currency.toLowerCase() === "aud" || "cad" || "nzd") {
            resolve(currency.toLowerCase());
          } else {
            resolve("usd");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default PriceService;
