import { IconBaseProps } from '../../types/types'

interface Props extends IconBaseProps {
	height?: number
	width?: number
}

function Logo({ className, onClick, height, width }: Props) {
	return (
		<div
			className={`${onClick ? 'cursor-pointer hover:scale-110 hover:rotate-3 transition-all duration-300 ' : ' '}` + className}
			onClick={onClick}>
			<svg
				id='Group_674'
				data-name='Group 674'
				xmlns='http://www.w3.org/2000/svg'
				width={width ?? '30'}
				height={height ?? '20'}
				viewBox='0 0 30 19.778'>
				<defs>
					<clipPath id='clip-path'>
						<rect id='Rectangle_1' data-name='Rectangle 1' width='30' height='19.778' fill='#101820' />
					</clipPath>
				</defs>
				<path
					id='Path_1'
					data-name='Path 1'
					d='M0,10.281l.7.659V28.9l-.7.638H3.638l0-.638V26.226l5.166-2.5v5.142l-.7.659h3.639V14.751H8.111l.7.659v5.2L3.64,23.1V10.94l.014-.659Z'
					transform='translate(0 -9.757)'
					fill='#101820'
				/>
				<path
					id='Path_2'
					data-name='Path 2'
					d='M361.863,10.066l-5.041,8.492V15.22l.7-.659h-3.64V29.305h3.64l-.7-.659v-4.7l1.378-2.362,3.978,7.724,3.475.011-.628-.515-5.181-9.988,4.872-8.275.707-.488Z'
					transform='translate(-335.653 -9.541)'
					fill='#101820'
				/>
				<g id='Group_2' data-name='Group 2'>
					<g id='Group_1' data-name='Group 1' clipPath='url(#clip-path)'>
						<path
							id='Path_3'
							data-name='Path 3'
							d='M145.989.646c-5.1.006-6.685.008-14.261.012L130.652,0l.615,3.556H137.6l-.012,15.556-1.026.666h4.824l-1.028-.666,0-15.556h6.323c0-.08.359-3.556.359-3.556Z'
							transform='translate(-123.953)'
							fill='#101820'
						/>
					</g>
				</g>
			</svg>
		</div>
	)
}

export default Logo
