import * as Yup from "yup";
import { PhoneNumberUtil } from "google-libphonenumber";

const passwordRegExp = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.^,/'"(){}|;:+=_\-\\[\]<>`])[A-Za-z\d@$!%*#?&.^,/'"(){}|;:+=_\-\\[\]<>`]{8,}$/;

// needs further support for countries outside AU
const validatePhoneNumber = (value: string | undefined) => {
	let result = false;
	try {
		const phoneUtil = PhoneNumberUtil.getInstance();
		result = phoneUtil.isValidNumber(phoneUtil.parse(value, "AU"));
	} catch (e) {
		result = false;
	}
	return result;
};

export const registerSchema = Yup.object().shape({
	firstName: Yup.string().required("Required"),
	lastName: Yup.string().required("Required"),
	email: Yup.string().email("Invalid Email").required("Required"),
	password: Yup.string().required("Required").min(8, "Password must be at least 8 characters").matches(passwordRegExp, "Password must contain 1 number and one special character"),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref("password"), null], "Passwords must match")
		.required("Required"),
});

export const updateUserSchema = Yup.object().shape({
	firstName: Yup.string().required("Required"),
	lastName: Yup.string().required("Required"),
	email: Yup.string().email("Invalid Email").required("Required"),
	avatar: Yup.string(),
});

export const loginSchema = Yup.object().shape({
	email: Yup.string().email("Invalid Email").required("Required"),
	password: Yup.string().required("Required"),
});

export const retrievePasswordSchema = Yup.object().shape({
	email: Yup.string().email("Invalid Email").required("Required"),
});

export const updatePasswordSchema = Yup.object().shape({
	currentPassword: Yup.string().required("Required"),
	newPassword: Yup.string().required("Required").min(8, "Password must be at least 8 characters").matches(passwordRegExp, "Password must contain 1 number and one special character"),
	confirmNewPassword: Yup.string()
		.required("Required")
		.when("newPassword", {
			is: (val: string) => (val && val.length > 0 ? true : false),
			then: Yup.string().oneOf([Yup.ref("newPassword")], "Passwords do not match"),
		}),
});

export const forgotPasswordSchema = Yup.object().shape({
	newPassword: Yup.string().required("Required").min(8, "Password must be at least 8 characters").matches(passwordRegExp, "Password must contain 1 number and one special character"),
	confirmNewPassword: Yup.string()
		.required("Required")
		.when("newPassword", {
			is: (val: string) => (val && val.length > 0 ? true : false),
			then: Yup.string().oneOf([Yup.ref("newPassword")], "Passwords do not match"),
		}),
});
