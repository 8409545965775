import { IconBaseProps } from '../../types/types'

interface Props extends IconBaseProps {}

function ShowPassword({ className, onClick }: Props) {
	return (
		<div
			className={`${onClick ? 'cursor-pointer hover:scale-110 hover:rotate-3 transition-all duration-300 ' : ' '}` + className}
			onClick={onClick}>
			<svg id='visibility_off_black_24dp' xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'>
				<path id='Path_886' data-name='Path 886' d='M0,0H16V16H0ZM0,0H16V16H0ZM0,0H16V16H0ZM0,0H16V16H0Z' fill='none' />
				<path
					id='Path_887'
					data-name='Path 887'
					d='M8.34,4.822A6.518,6.518,0,0,1,14.224,8.5a6.445,6.445,0,0,1-1.608,2.085l.941.942A7.886,7.886,0,0,0,15.679,8.5,7.89,7.89,0,0,0,5.911,3.867l1.1,1.1A6.735,6.735,0,0,1,8.34,4.822Zm-.714.762L9.007,6.967a1.668,1.668,0,0,1,.854.855l1.381,1.383a3.131,3.131,0,0,0,.093-.715,2.994,2.994,0,0,0-3-3A2.828,2.828,0,0,0,7.626,5.584ZM1.674,3.4,3.462,5.19A7.842,7.842,0,0,0,1,8.5a7.891,7.891,0,0,0,7.34,5.012,7.816,7.816,0,0,0,2.882-.548L13.5,15.247l.941-.942L2.615,2.45Zm5,5.012L8.42,10.155a.334.334,0,0,1-.08.013A1.67,1.67,0,0,1,6.671,8.5C6.671,8.464,6.678,8.444,6.678,8.411ZM4.41,6.139,5.577,7.308A3.078,3.078,0,0,0,5.337,8.5a3.005,3.005,0,0,0,4.184,2.766l.654.655a6.951,6.951,0,0,1-1.835.254A6.518,6.518,0,0,1,2.455,8.5,6.615,6.615,0,0,1,4.41,6.139Z'
					transform='translate(-0.34 -0.832)'
					fill='#101820'
				/>
			</svg>
		</div>
	)
}

export default ShowPassword
