import { ChangeEvent, ReactNode } from "react";
import { HTMLInputTypeAttribute } from "react";

type Props = {
	placeholder?: string;
	className?: string;
	value: string;
	onChange: (e: ChangeEvent<HTMLInputElement>) => void;
	type?: "NUMBER" | "TEXT";
	inputType?: HTMLInputTypeAttribute;
	icon?: ReactNode;
	error?: string;
	touched?: boolean;
	isDisabled?: boolean;
};

function ValidationInput({ placeholder, isDisabled, className, value, onChange, type, inputType, icon, error, touched }: Props): JSX.Element {
	const validateIsNumber = (e: ChangeEvent<HTMLInputElement>) => {
		if (isNaN(Number(e.target.value))) return;
		onChange(e);
	};

	return (
		<div className="relative w-full">
			<input disabled={isDisabled ? true : false} className={`input-primary ` + className} type={inputType} placeholder={placeholder} value={value} onChange={(e) => (type === "NUMBER" ? validateIsNumber(e) : onChange(e))} />
			{icon && <div className="absolute right-3 top-[20px]">{icon}</div>}
			{error && touched && <div className="bg-white p-2 text-error-base border-error-base  border-[1px] rounded-[3px] mb-4 text-[14px]">{error}</div>}
		</div>
	);
}

export default ValidationInput;
