import { useReducer, useEffect, useState, createContext, Dispatch } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/home";
import Loading from "./pages/loading";
import globalReducer from "./utils/reducers/global-reducer";
import { GlobalState, GlobalStateAction, globalInitialState } from "./utils/reducers/global-reducer";
import { AuthRequiredRoutes, InitialSubscribeRoute, NotAuthRoutes } from "./utils/custom-routes";
import AuthService from "./services/auth";
import Snackbar from "./components/core/snack";
import Login from "./pages/login";
import Subscribe from "./pages/subscribe";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import AccountDetails from "./pages/account-details";
import SubscriptionSettings from "./pages/subscription-settings";
import UpdatePaymentMethod from "./pages/update-payment-method";
import CancelPlan from "./pages/cancel-plan";
import UpdatePlan from "./pages/update-plan";
import asyncTimeout from "./utils/async-timeout";
import UpdatePassword from "./pages/update-password";
import RetrievePassword from "./pages/retrieve-password";
import Complete from "./pages/complete";
import SignupWithPlan from "./pages/signup-with-plan";

export const GlobalContext = createContext<{ state: GlobalState; dispatch: Dispatch<GlobalStateAction> }>({
	state: globalInitialState,
	dispatch: () => null,
});

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY ?? "");

const options = {
	fonts: [
		{
			src: require("./assets/fonts/Roboto-Regular.ttf"),
			family: "Roboto",
		},
	],
};

function App() {
	const [state, dispatch] = useReducer(globalReducer, globalInitialState);
	const [isLoadingUser, setIsLoadingUser] = useState(true);
	const token = localStorage.getItem("HTKToken");

	const getUserData = async () => {
		try {
			const [userData] = await Promise.all([AuthService.getMe(), asyncTimeout(1000)]);
			console.log(userData);
			dispatch({ type: "setUser", data: userData });
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoadingUser(false);
		}
	};

	useEffect(() => {
		if (!token) {
			setIsLoadingUser(false);
			dispatch({ type: "setUser", data: undefined });
			return;
		}
		getUserData();
	}, []);

	if (isLoadingUser) return <Loading />;

	return (
		<Elements stripe={stripePromise} options={options}>
			<Router>
				<GlobalContext.Provider value={{ state, dispatch }}>
					<div className="max-w-md m-auto outline-[2px] relative">
						<Snackbar isOpen={state.snack.isOpen} message={state.snack.message} severity={state.snack.severity} setIsOpen={(open) => dispatch({ type: "setSnack", data: { ...state.snack, isOpen: open } })} timeoutMS={5000} />

						<Routes>
							{/* Free Routes */}
							<Route path="/complete" element={<Complete />} />

							{/* Auth Routes */}
							<Route element={<AuthRequiredRoutes />}>
								<Route path="/" element={<Home />} />
								<Route path="/account-details" element={<AccountDetails />} />
								<Route path="/subscription-settings" element={<SubscriptionSettings />} />
								<Route path="/update-payment-method" element={<UpdatePaymentMethod />} />
								<Route path="/cancel-plan" element={<CancelPlan />} />
								<Route path="/update-plan" element={<UpdatePlan />} />
								<Route path="/update-password" element={<UpdatePassword />} />
							</Route>

							{/* Must Not be Authed Routes */}
							<Route element={<NotAuthRoutes />}>
								<Route path="/signup-with-plan" element={<SignupWithPlan />} />
								<Route path="/login" element={<Login />} />
								<Route path="/retrieve-password" element={<RetrievePassword />} />
							</Route>

							<Route element={<InitialSubscribeRoute />}>
								<Route path="/subscribe" element={<Subscribe />} />
							</Route>

							{/* Default Route */}
							<Route path="*" element={<Navigate to="/" replace />} />
						</Routes>

						{/* } */}
					</div>
				</GlobalContext.Provider>
			</Router>
		</Elements>
	);
}

export default App;
