import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Arrow from '../assets/icons/arrow'
import Logo from '../assets/icons/logo'
import RetrieveEnterCode from '../components/retrieve-enter-code'
import RetrieveEnterEmail from '../components/retrieve-enter-email'
import RetrieveEnterPassword from '../components/retrieve-enter-password'

export type RetrievePasswordPages = 'SEND_EMAIL' | 'ENTER_CODE' | 'NEW_PASSWORD'

function RetrievePassword() {
	const navigate = useNavigate()

	const [page, setPage] = useState<RetrievePasswordPages>('SEND_EMAIL')
	const [email, setEmail] = useState('')
	const [jwt, setJwt] = useState('')

	const renderPage = () => {
		switch (page) {
			case 'SEND_EMAIL':
				return <RetrieveEnterEmail setPage={setPage} setEmail={setEmail} />
			case 'ENTER_CODE':
				return <RetrieveEnterCode setPage={setPage} email={email} setJwt={setJwt} />
			case 'NEW_PASSWORD':
				return <RetrieveEnterPassword jwt={jwt} />
			default:
				return <RetrieveEnterEmail setPage={setPage} setEmail={setEmail} />
		}
	}

	const navigationSwitch = () => {
		switch (page) {
			case 'SEND_EMAIL':
				navigate(-1)
				break
			case 'ENTER_CODE':
				setPage('SEND_EMAIL')
				break
			case 'NEW_PASSWORD':
				setPage('ENTER_CODE')
				break
			default:
				navigate(-1)
				break
		}
	}

	return (
		<div className='px-4'>
			<div className='w-full flex justify-between items-center mb-4'>
				<Arrow onClick={() => navigationSwitch()} />
				<Logo className='w-fit my-4 ' />
			</div>

			{renderPage()}
		</div>
	)
}

export default RetrievePassword
