import axios from "axios";
import { PartialUserUpdate, User, UserLoggedIn } from "../types/types";
import Instance from "../utils/axios";
import Crudable from "./crudable";

function isUserObject(user: any): user is User {
  if (!user) return false;
  return (
    "id" in user &&
    "email" in user &&
    "firstName" in user &&
    "lastName" in user &&
    "userStripe" in user &&
    "userProgram" in user
  );
}

type UserResetVerified = {
  jwtToken: string;
};

function isForgotPasswordVerified(userJWT: any): userJWT is UserResetVerified {
  if (!userJWT) return false;
  return "jwtToken" in userJWT;
}

const networkErrorMessage =
  "There was an error with your connection, please try again";

class AuthService implements Crudable<User | UserLoggedIn> {
  createOne = async (
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    hasAgreedMarketingEmail: boolean
  ): Promise<UserLoggedIn> => {
    try {
      const result = await Instance.post("auth/register", {
        firstName,
        lastName,
        email,
        password,
        hasAgreedMarketingEmail,
      });

      console.log(result);

      let userLoginData = result.data as UserLoggedIn;

      return userLoginData;
    } catch (error) {
      console.log(error);
      if (error && axios.isAxiosError(error)) {
        if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED")
          throw Error(networkErrorMessage);
      }

      throw Error(
        "Something went wrong, double check your details and try again"
      );
    }
  };

  getOne = async (): Promise<User> => {
    // get a user's details
    throw Error("Not Implemented");
  };

  updateOne = async (
    userId: string,
    partialUser: PartialUserUpdate
  ): Promise<User> => {
    const { firstName, lastName, email, avatar, unitOfMeasurement } =
      partialUser;

    try {
      const result = await Instance.put(`users/${userId}`, {
        firstName,
        lastName,
        email,
        avatar,
        unitOfMeasurement,
      });

      let userUpdateResult = result.data as User;

      return userUpdateResult;
    } catch (error) {
      console.log(error);
      if (error && axios.isAxiosError(error)) {
        if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED")
          throw Error(networkErrorMessage);
      }

      throw Error(
        "Something went wrong, double check your details and try again"
      );
    }
  };

  deleteOne = async (userId: string) => {
    try {
      const result = await Instance.delete(`users/${userId}/delete-account`);

      console.log(result);
    } catch (error) {
      console.log(error);
      if (error && axios.isAxiosError(error)) {
        if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED")
          throw Error(networkErrorMessage);
      }

      throw Error(
        "Something went wrong, please contact an admin to delete your account"
      );
    }
  };

  loginUser = async (
    email: string,
    password: string
  ): Promise<UserLoggedIn> => {
    try {
      const result = await Instance.post("auth/login", {
        email,
        password,
      });

      console.log(result.data);

      let userLoginData = result.data as UserLoggedIn;
      return userLoginData;
    } catch (error) {
      console.log(error);
      if (error && axios.isAxiosError(error)) {
        if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED")
          throw Error(networkErrorMessage);
      }

      throw Error("Invalid email or password");
    }
  };

  updatePassword = async (
    userId: string,
    currentPassword: string,
    newPassword: string
  ): Promise<boolean> => {
    try {
      const result = await Instance.put(`users/${userId}/password`, {
        currentPassword,
        newPassword,
      });

      console.log(result);

      return true;
    } catch (error) {
      console.log(error);
      if (error && axios.isAxiosError(error)) {
        if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED")
          throw Error(networkErrorMessage);
      }

      throw Error("Invalid password");
    }
  };

  sendVerificationCode = async (email: string): Promise<boolean> => {
    try {
      const result = await Instance.post(`auth/send-verification-code`, {
        email,
      });

      console.log(result);

      return true;
    } catch (error) {
      console.log(error);
      if (error && axios.isAxiosError(error)) {
        if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED")
          throw Error(networkErrorMessage);
      }

      throw Error("Could not send code, ensure your email is correct");
    }
  };

  verifyCode = async (email: string, code: string): Promise<string> => {
    try {
      const result = await Instance.post(
        `auth/authenticate-verification-code`,
        {
          email,
          code,
        }
      );

      let userJWT = result.data;

      return userJWT.jwtToken;
    } catch (error) {
      console.log(error);
      if (error && axios.isAxiosError(error)) {
        if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED")
          throw Error(networkErrorMessage);
      }

      throw Error("Invalid code, please try again");
    }
  };

  forgotPassword = async (
    newPassword: string,
    token: string
  ): Promise<boolean> => {
    console.log(newPassword);
    console.log(token);
    try {
      const result = await Instance.post(
        `auth/forgot-password`,
        {
          newPassword,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      console.log(result);

      return true;
    } catch (error) {
      console.log(error);
      if (error && axios.isAxiosError(error)) {
        if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED")
          throw Error(networkErrorMessage);
      }

      throw Error("Invalid code, please try again");
    }
  };

  static getMe = async (): Promise<User | undefined> => {
    try {
      const result = await Instance.get("users");

      let user = result.data.userDetails as User;
      return user;
    } catch (error) {
      console.log(error);
      if (error && axios.isAxiosError(error)) {
        if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED")
          throw Error(networkErrorMessage);
      }

      throw Error("User does not exist");
    }
  };
}

export default AuthService;
