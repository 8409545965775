import { useState } from 'react'
import CancelSubscriptionFeedback from '../components/cancel-subscription-feedback'
import CancelSubscriptionWarning from '../components/cancel-subscription-warning'

export type CancelPages = 'Warning' | 'Cancel'

function CancelPlan() {
	const [page, setPage] = useState<CancelPages>('Warning')

	const renderSwitch = () => {
		switch (page) {
			case 'Warning':
				return <CancelSubscriptionWarning setPage={setPage} />
			case 'Cancel':
				return <CancelSubscriptionFeedback setPage={setPage} />
			default:
				return <CancelSubscriptionWarning setPage={setPage} />
		}
	}

	return <div>{renderSwitch()}</div>
}

export default CancelPlan
