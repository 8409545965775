import { useFormik } from 'formik'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ShowPassword from '../assets/icons/show-password'
import AuthService from '../services/auth'
import asyncTimeout from '../utils/async-timeout'
import errorPopupParser from '../utils/error-popup-parser'
import useGlobalState from '../utils/use-global-state'
import { forgotPasswordSchema } from '../utils/validation-schemas'
import Button from './core/button'
import ValidationInput from './core/validation-input'

type Props = {
	jwt: string
}

function RetrieveEnterPassword({ jwt }: Props) {
	const auth = new AuthService()
	const { dispatch } = useGlobalState()
	const navigate = useNavigate()

	const [isLoading, setIsLoading] = useState(false)
	const [newPasswordType, setNewPasswordType] = useState<'password' | 'text'>('password')
	const [confirmNewPasswordType, setConfirmNewPasswordType] = useState<'password' | 'text'>('password')

	const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
		initialValues: {
			newPassword: '',
			confirmNewPassword: '',
		},
		onSubmit: (values) => {
			updateUserPassword()
		},
		validationSchema: forgotPasswordSchema,
	})

	const updateUserPassword = async () => {
		setIsLoading(true)
		try {
			await Promise.all([auth.forgotPassword(values.newPassword, jwt), asyncTimeout(800)])
			dispatch({
				type: 'setSnack',
				data: {
					isOpen: true,
					message: 'Your password was successfully reset',
					severity: 'success',
				},
			})
			navigate('/login')
		} catch (error) {
			errorPopupParser(error, dispatch)
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<div>
			<h2 className='text-[20px] font-bold text-grey-base font-chakraPetch mt-12'>UPDATE PASSWORD</h2>
			<h1 className='text-[36px] font-bold leading-10 font-chakraPetch mb-2'>NEW PASSWORD</h1>
			<p className='font-roboto text-[14px] leading-4 text-grey-text-base opacity-80 mb-4'>Enter a new password for your HTK account.</p>

			<ValidationInput
				value={values.newPassword}
				error={errors.newPassword}
				touched={touched.newPassword}
				onChange={(e) => setFieldValue('newPassword', e.target.value)}
				placeholder='PASSWORD'
				inputType={newPasswordType}
				icon={<ShowPassword onClick={() => setNewPasswordType(newPasswordType === 'password' ? 'text' : 'password')} />}
			/>

			<ValidationInput
				value={values.confirmNewPassword}
				error={errors.confirmNewPassword}
				touched={touched.confirmNewPassword}
				onChange={(e) => setFieldValue('confirmNewPassword', e.target.value)}
				placeholder='CONFIRM PASSWORD'
				inputType={confirmNewPasswordType}
				icon={<ShowPassword onClick={() => setConfirmNewPasswordType(confirmNewPasswordType === 'password' ? 'text' : 'password')} />}
			/>

			<Button text='SET PASSWORD' onClick={handleSubmit} className='mt-20' isLoading={isLoading} />
		</div>
	)
}

export default RetrieveEnterPassword
