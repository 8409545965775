import { GlobalStateAction } from "./reducers/global-reducer"

const errorPopupParser = (error: any, dispatch: (options: GlobalStateAction) => void) => {
    let message
    if (error instanceof Error) message = error.message
    else message = String(error)
    dispatch({ type: 'setSnack', data: {
        isOpen: true,
        severity: 'error',
        message: message
    }})
}

export default errorPopupParser