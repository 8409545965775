import { useFormik } from 'formik'
import { useState } from 'react'
import QuestionMark from '../assets/icons/question-mark'
import { RetrievePasswordPages } from '../pages/retrieve-password'
import AuthService from '../services/auth'
import asyncTimeout from '../utils/async-timeout'
import errorPopupParser from '../utils/error-popup-parser'
import useGlobalState from '../utils/use-global-state'
import Button from './core/button'
import VerificationCodeInput from './verification-code-input'

type Props = {
	setPage: (page: RetrievePasswordPages) => void
	email: string
	setJwt: (jwt: string) => void
}

function RetrieveEnterCode({ setPage, email, setJwt }: Props) {
	const auth = new AuthService()
	const { dispatch } = useGlobalState()

	const [isLoading, setIsLoading] = useState(false)

	const verifyCode = async (code: string) => {
		setIsLoading(true)
		try {
			const [codeVerifyResult] = await Promise.all([auth.verifyCode(email, code), asyncTimeout(800)])

			setJwt(codeVerifyResult)
			setPage('NEW_PASSWORD')
		} catch (error) {
			errorPopupParser(error, dispatch)
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<div>
			<h2 className='text-[20px] font-bold text-grey-base font-chakraPetch'>RETRIEVE ACCOUNT</h2>
			<h1 className='text-[36px] font-bold leading-10 font-chakraPetch'>AUTHENTICATION</h1>

			<p className='font-roboto text-[14px] mb-4 text-grey-text-base opacity-80 leading-4'>We have sent you a 6 digit code to {email}.</p>

			<VerificationCodeInput length={6} handleCodeComplete={verifyCode} />

			<div className='px-4 py-2  bg-[#EE534033] flex gap-4'>
				<QuestionMark />
				<div>
					<p className='font-chakraPetch text-[20px] font-bold text-grey-text-base '>WHY DO WE AUTHENTICATE?</p>
					<p className='font-roboto text-[14px] text-grey-text-base opacity-80 leading-4'>
						We use your email to verify you are a real person. We also use your email as a security measure for your account.
					</p>
				</div>
			</div>

			<Button text='NEXT' onClick={() => null} className='mt-12' isLoading={isLoading} disabled />
		</div>
	)
}

export default RetrieveEnterCode
