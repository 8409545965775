import axios from 'axios'
import { PresignedUpload } from '../types/types'
import Instance from '../utils/axios'

const networkErrorMessage = 'There was an error with your connection, please try again'

class FileService {
	getSingleUploadURL = async (imageKey: string): Promise<PresignedUpload> => {
		try {
			const result = await Instance.post(`image-upload/upload-presigned-url`, {
				imageKey,
			})

			console.log(result)

			return result.data
		} catch (error) {
			console.log(error)
			if (error && axios.isAxiosError(error)) {
				if (error?.code === 'ERR_NETWORK' || error?.code === 'ECONNABORTED') throw Error(networkErrorMessage)
			}

			throw Error('Invalid upload path')
		}
	}

	getSingleDownloadURL = async (imageKey: string): Promise<string> => {
		try {
			const result = await Instance.post(`image-upload/download-presigned-url`, {
				imageKey,
			})

			return result.data
		} catch (error) {
			console.log(error)
			if (error && axios.isAxiosError(error)) {
				if (error?.code === 'ERR_NETWORK' || error?.code === 'ECONNABORTED') throw Error(networkErrorMessage)
			}

			throw Error('Invalid upload path')
		}
	}

	uploadWithPresignedURL = async (presignedURL: string, file: File): Promise<string> => {
		const buffer = await file.arrayBuffer()

		try {
			const myHeaders = new Headers({ 'Content-Type': file.type })
			const result = await fetch(presignedURL, {
				method: 'PUT',
				headers: myHeaders,
				body: buffer,
			})

			return result.url
		} catch (error) {
			console.log(error)
			if (error && axios.isAxiosError(error)) {
				if (error?.code === 'ERR_NETWORK' || error?.code === 'ECONNABORTED') throw Error(networkErrorMessage)
			}

			throw Error('File upload failed')
		}
	}
}

export default FileService
