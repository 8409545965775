import React from "react";
import { SubscriptionPlan } from "../types/types";
import { StripePlan } from "../types/stripe-types";
import { months } from "moment";

type Props = {
  plan: StripePlan;
  isActive: boolean;
  setIsActive: () => void;
};

function PlanCard({ plan, isActive, setIsActive }: Props) {
  return (
    <div
      className={`border-2 mb-3 p-2 ${
        isActive ? "border-orange-base" : "border-grey-light"
      } 
            transition-all duration-300 hover:shadow-md cursor-pointer`}
      onClick={setIsActive}
    >
      <div className="flex items-center justify-between mb-1">
        <>
          <p className="bg-orange-base text-white font-semibold p-1 text-[14px] font-chakraPetch">
            7 DAY FREE TRIAL
          </p>
          <p className={`font-roboto text-[14px] 'text-grey-text-base`}>
            $
            {plan.recurring.interval === "month"
              ? plan.unit_amount / 100
              : Number((plan.unit_amount / 1200).toFixed(2))}
            Per Month
          </p>
        </>
      </div>
      <div className="font-bold text-[20px] font-chakraPetch">
        ${plan.unit_amount / 100} PER{" "}
        {plan.recurring.interval === "month" ? "MONTH" : "YEAR"}
      </div>
      <p className="font-roboto text-[14px] leading-4 text-grey-text-base opacity-80">
        {plan.recurring.interval === "month"
          ? "A monthly billed subscription with full access to all HTK training programs and challenges."
          : "An annually billed subscription with full access to all HTK training programs and challenges at a discount!"}
      </p>
    </div>
  );
}

export default PlanCard;
