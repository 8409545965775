import Logo from "../../assets/icons/logo";
import Button from "../core/button";

type Props = {
	className?: string;
	onLoginPress: () => void;
	shouldShowLoginButton: boolean;
};
const Header = ({ className, onLoginPress, shouldShowLoginButton }: Props) => {
	return (
		<div className={` flex flex-row items-center justify-between px-2 border-b-2 border-[#BBBDC0]  ${className}`}>
			<Logo className="mx-auto w-fit my-4 " height={20} width={30} />
			{shouldShowLoginButton && (
				<div className="flex flex-row items-center justify-end flex-1">
					<p className={`font-roboto text-[14px] opacity-50`}>Already have an account?</p>
					<Button text="LOGIN" className="w-fit ml-2 p-2 rounded-[3px]" onClick={onLoginPress} />
				</div>
			)}
		</div>
	);
};

export default Header;
