import { useFormik } from 'formik'
import { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Arrow from '../assets/icons/arrow'
import AvatarPicker from '../components/avatar-picker'
import Button from '../components/core/button'
import ValidationInput from '../components/core/validation-input'
import AuthService from '../services/auth'
import FileService from '../services/file'
import { PartialUserUpdate, PresignedUpload } from '../types/types'
import asyncTimeout from '../utils/async-timeout'
import errorPopupParser from '../utils/error-popup-parser'
import refreshUserData from '../utils/refresh-user-data'
import useGlobalState from '../utils/use-global-state'
import { updateUserSchema } from '../utils/validation-schemas'

function AccountDetails() {
	const navigate = useNavigate()

	const { state, dispatch } = useGlobalState()
	const { user } = state

	const auth = new AuthService()
	const fileService = new FileService()

	const [isLoading, setIsLoading] = useState(false)
	const [avatarURL, setAvatarURL] = useState<PresignedUpload>()
	const [avatarPreview, setAvatarPreview] = useState('')
	const [isAvatarUploading, setIsAvatarUploading] = useState(false)

	const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
		initialValues: {
			firstName: user!.firstName,
			lastName: user!.lastName,
			email: user!.email,
			avatar: user?.avatar ?? '',
		},
		onSubmit: (values) => {
			updateUser()
		},
		validationSchema: updateUserSchema,
	})

	const handleAvatarUpdate = async (e: ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files || e.target.files?.length === 0) return
		setAvatarURL(undefined)
		setAvatarPreview('')
		setIsAvatarUploading(true)
		try {
			const presignedURL = await fileService.getSingleUploadURL(e.target.files[0].name)
			await fileService.uploadWithPresignedURL(presignedURL.url, e.target.files[0])
			setAvatarURL(presignedURL)
		} catch (error) {
			errorPopupParser(error, dispatch)
			setAvatarURL(undefined)
		} finally {
			setIsAvatarUploading(false)
		}
	}

	const updateUser = async () => {
		setIsLoading(true)

		const partialUser: PartialUserUpdate = {
			firstName: values.firstName,
			lastName: values.lastName,
			email: values.email,
			avatar: avatarURL?.imageKey ?? avatarPreview,
			unitOfMeasurement: user!.unitOfMeasurement
		}

		try {
			await Promise.all([auth.updateOne(user!.id, partialUser), asyncTimeout(1000)])

			refreshUserData(dispatch)

			dispatch({
				type: 'setSnack',
				data: {
					isOpen: true,
					message: 'Account details updated!',
					severity: 'success',
				},
			})

			setTimeout(() => {
				navigate('/')
			}, 1000)
		} catch (error) {
			errorPopupParser(error, dispatch)
		} finally {
			setIsLoading(false)
		}
	}

	const getAvatar = async (imageKey: string) => {
		try {
			const avatarResult = await fileService.getSingleDownloadURL(imageKey)
			setAvatarPreview(avatarResult)
		} catch (error) {
			errorPopupParser(error, dispatch)
		}
	}

	useEffect(() => {
		if (!avatarURL?.imageKey) return
		getAvatar(avatarURL.imageKey)
	}, [avatarURL])

	useEffect(() => {
		if (!user?.avatar) return

		getAvatar(user.avatar)
	}, [])

	return (
		<div>
			<div className='border-b-2 border-[#BBBDC0]  flex gap-4 items-center p-4 mb-4'>
				<Arrow onClick={() => navigate(-1)} />
				<p className='font-bold font-chakraPetch text-[20px]'>ACCOUNT DETAILS</p>
			</div>

			<div className='px-4 mb-6'>
				<p className='font-bold font-chakraPetch text-[20px]'>GENERAL ACCOUNT DETAILS</p>
				<p className='font-roboto text-[14px] mb-4 text-grey-text-base'>Update your name and email details for your HTK account.</p>

				<div className='grid grid-cols-2 gap-1'>
					<ValidationInput
						value={values.firstName}
						error={errors.firstName}
						touched={touched.firstName}
						onChange={(e) => setFieldValue('firstName', e.target.value)}
						placeholder='FIRST NAME'
					/>

					<ValidationInput
						value={values.lastName}
						error={errors.lastName}
						touched={touched.lastName}
						onChange={(e) => setFieldValue('lastName', e.target.value)}
						placeholder='LAST NAME'
					/>
				</div>

				<ValidationInput
					value={values.email}
					error={errors.email}
					touched={touched.email}
					onChange={(e) => setFieldValue('email', e.target.value)}
					placeholder='LAST NAME'
				/>
			</div>

			<div className='px-4'>
				<p className='font-bold font-chakraPetch text-[20px]'>PROFILE PICTURE</p>
				<p className='font-roboto text-[14px] mb-4 text-grey-text-base'>Personalise your account with a custom profile picture.</p>

				<AvatarPicker onAvatarChange={handleAvatarUpdate} avatarPreview={avatarPreview} isUploading={isAvatarUploading} />

				<Button text='SAVE' onClick={handleSubmit} className='mb-4' isLoading={isLoading} />
			</div>
		</div>
	)
}

export default AccountDetails
