import { useEffect, useState } from "react";
import Header from "../components/signup-with-plan/header";
import { useNavigate } from "react-router-dom";
import { UserCreate } from "../types/types";
import PlanCard from "../components/plan-card";
import { useFormik } from "formik";
import AuthService from "../services/auth";
import { registerSchema } from "../utils/validation-schemas";
import useGlobalState from "../utils/use-global-state";
import errorPopupParser from "../utils/error-popup-parser";
import asyncTimeout from "../utils/async-timeout";
import UserDetails from "../components/signup-with-plan/user-details";
import ComponentAnimator from "../components/core/component-animator";
import BillingDetails from "../components/signup-with-plan/billing-details";
import { useSignupStore } from "../utils/store/signup-store";
import Loading from "./loading";
import PriceService from "../services/price";
import axios from "axios";
import { StripePlan } from "../types/stripe-types";

type Props = {
  className?: string;
};
const SignupWithPlan = ({ className }: Props) => {
  const [currency, setCurrency] = useState("USD");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<StripePlan | undefined>(
    undefined
  );
  const { setUser, user: userData } = useSignupStore();
  const { dispatch } = useGlobalState();
  const authService = new AuthService();
  const [plans, setPlans] = useState<StripePlan[] | undefined>(undefined);

  const getStripePlans = async () => {
    try {
      const pricesResponse = await PriceService.getPrices();
      setPlans(pricesResponse);
      return pricesResponse;
    } catch (error) {
      errorPopupParser(error, dispatch);
    }
  };

  useEffect(() => {
    getStripePlans();
  }, []);

  const formik = useFormik<UserCreate>({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      hasAgreedMarketingEmail: true,
    },
    onSubmit: (values) => {
      registerUser(values);
    },
    validationSchema: registerSchema,
  });

  const registerUser = async (userData: UserCreate) => {
    setIsLoading(true);
    try {
      const [userResult] = await Promise.all([
        authService.createOne(
          userData.firstName,
          userData.lastName,
          userData.email,
          userData.password,
          userData.hasAgreedMarketingEmail
        ),
        asyncTimeout(1000),
      ]);
      console.log("Response from the server while registering user", {
        userResult,
      });
      localStorage.setItem("HTKToken", userResult.jwtToken);
      setUser(userResult.userDetails);
    } catch (error) {
      errorPopupParser(error, dispatch);
    } finally {
      setIsLoading(false);
    }
  };

  const renderSubscriptions = () => {
    if (isLoading) return <Loading />;
    if (!plans) return;
    return plans.map((plan, index) => {
      return (
        <ComponentAnimator index={index} key={index}>
          <PlanCard
            plan={plan}
            isActive={selectedPlan === plan}
            setIsActive={() =>
              plan === selectedPlan
                ? setSelectedPlan(undefined)
                : setSelectedPlan(plan)
            }
          />
        </ComponentAnimator>
      );
    });
  };

  return (
    <div className={` w-full h-screen  ${className} `}>
      <Header
        className={`mb-2 fixed w-full max-w-md  bg-white`}
        shouldShowLoginButton={false}
        onLoginPress={() => navigate("/login")}
      />
      <div className={`w-full px-2 overflow-y-auto pt-16`}>
        <div>
          <p className={`font-bold text-[20px] font-chakraPetch`}>
            SELECT YOUR PLAN
          </p>
          <p
            className={`font-roboto text-[14px] leading-4 text-grey-text-base opacity-80 mb-1`}
          >{`All options below include a 7-day FREE TRIAL!`}</p>
          <p
            className={`font-roboto text-[14px] leading-4 text-grey-text-base opacity-80 mb-2`}
          >{`You will only be charged when the trial period ends unless you cancel prior.`}</p>
        </div>
        {renderSubscriptions()}
        {userData.id === "" && (
          <ComponentAnimator index={2} className={`mb-2`}>
            <UserDetails
              formik={formik}
              className={` transition-allduration-300 ${
                selectedPlan ? "" : " opacity-20 hover:cursor-not-allowed"
              }`}
              isDisabled={selectedPlan ? false : true}
            />
          </ComponentAnimator>
        )}
        <div
          onClick={() =>
            userData.id === "" && selectedPlan ? formik.handleSubmit() : null
          }
          className={`text-[14px] px-[1.5rem] py-3 rounded-[3px] mb-2 uppercase font-roboto transition-all w-full font-medium flex gap-2 justify-center cursor-pointer hover:scale-[1.03] duration-300 ${
            userData.id === ""
              ? `bg-orange-base hover:bg-orange-base text-white`
              : `bg-white border-orange-base text-orange-base border-[1px] hover:cursor-not-allowed `
          } ${selectedPlan ? "" : "opacity-20 hover:cursor-not-allowed"}`}
        >
          {userData.id !== "" ? "ACCOUNT CREATED" : "CREATE ACCOUNT"}
        </div>

        {selectedPlan && userData.id !== "" && (
          <ComponentAnimator index={3}>
            <BillingDetails
              selectedPlan={selectedPlan}
              isDisabled={userData.id === "" || !selectedPlan ? true : false}
            />
          </ComponentAnimator>
        )}
      </div>
    </div>
  );
};

export default SignupWithPlan;
