import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/icons/logo";
import ShowPassword from "../assets/icons/show-password";
import Button from "../components/core/button";
import ValidationInput from "../components/core/validation-input";
import AuthService from "../services/auth";
import asyncTimeout from "../utils/async-timeout";
import errorPopupParser from "../utils/error-popup-parser";
import useGlobalState from "../utils/use-global-state";
import { loginSchema } from "../utils/validation-schemas";

function Login() {
	const [passwordType, setPasswordType] = useState<"password" | "text">("password");
	const [isLoading, setIsLoading] = useState(false);

	const auth = new AuthService();

	const navigate = useNavigate();
	const { dispatch } = useGlobalState();

	const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
		initialValues: {
			email: "",
			password: "",
		},
		onSubmit: (values) => {
			loginUser();
		},
		validationSchema: loginSchema,
	});

	const loginUser = async () => {
		setIsLoading(true);
		try {
			const [loginResult] = await Promise.all([auth.loginUser(values.email, values.password), asyncTimeout(1000)]);

			localStorage.setItem("HTKToken", loginResult.jwtToken);
			dispatch({ type: "setUser", data: loginResult.userDetails });

			console.log("Login response", loginResult);
		} catch (error) {
			errorPopupParser(error, dispatch);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="p-4">
			<Logo className="mx-auto w-fit my-4 " height={40} width={60} />
			<h2 className="text-[20px] font-bold text-grey-base font-chakraPetch">GET STUCK IN</h2>
			<h1 className="text-[36px] font-bold font-chakraPetch">LET'S BEGIN!</h1>

			<p className="font-roboto text-[14px] mb-4">Sign back into your existing Hard To Kill account or create a new account.</p>

			<ValidationInput value={values.email} error={errors.email} touched={touched.email} onChange={(e) => setFieldValue("email", e.target.value)} placeholder="EMAIL" />

			<ValidationInput
				value={values.password}
				error={errors.password}
				touched={touched.password}
				onChange={(e) => setFieldValue("password", e.target.value)}
				placeholder="PASSWORD"
				inputType={passwordType}
				icon={<ShowPassword onClick={() => setPasswordType(passwordType === "password" ? "text" : "password")} />}
			/>

			<Button text="SIGN IN" onClick={handleSubmit} className="my-6" isLoading={isLoading} />

			<p className="font-roboto text-[14px] text-grey-base font-bold mb-8">
				FORGOTTEN YOUR PASSWORD?{" "}
				<span className="text-black cursor-pointer" onClick={() => navigate("/retrieve-password")}>
					RETRIEVE HERE!
				</span>
			</p>

			<h1 className="text-[24px] font-bold leading-9 font-chakraPetch">NO ACCOUNT? NO WORRIES!</h1>
			<p className="font-roboto text-[14px] mb-4">Let's get you signed up so you can start your journey on being the best you!</p>
			<Button text="CREATE ACCOUNT" onClick={() => navigate("/signup-with-plan")} />
		</div>
	);
}

export default Login;
