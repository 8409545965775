import React, { useEffect, useState } from 'react'

type Props = {
	isOpen: boolean
	setIsOpen: (isOpen: boolean) => void
	children: JSX.Element
}

function ModalWrapper({ isOpen, setIsOpen, children }: Props) {
	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = 'hidden'
			window.scrollTo(0, 0)
		} else document.body.style.overflow = 'unset'
	}, [isOpen])

	useEffect(() => {
		return () => {
			document.body.style.overflow = 'unset'
		}
	}, [])

	return (
		<div
			className={`${
				isOpen ? 'ease-in duration-200 opacity-100' : 'ease-out duration-300 opacity-0 pointer-events-none'
			} fixed inset-0 bg-[#10182033] transition-opacity h-screen z-10 w-full top-0 left-0 max-w-md ease-in-out duration-500 m-auto`}
			onClick={() => setIsOpen(false)}>
			<div
				className={`${isOpen ? 'translate-y-0' : 'translate-y-full'} h-full transform transition ease-in-out duration-500 w-full flex flex-col`}
				onClick={() => setIsOpen(false)}>
				{children}
			</div>
		</div>
	)
}

export default ModalWrapper
