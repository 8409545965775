import { User } from "../../types/types";

export const InitialUserState: User = {
	email: "",
	firstName: "",
	id: "",
	lastName: "",
	isRestricted: false,
	unitOfMeasurement: "Metric",
	userSubscription: {
		createdAt: "",
		isFreeTrialFinished: false,
		paymentType: null,
		productId: null,
		stripeCustomerId: "",
		updatedAt: "",
		userId: "",
		hasFreeAccess: false,
	},
	userProgram: [],
};
