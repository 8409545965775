import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Arrow from "../assets/icons/arrow";
import Settings from "../assets/icons/settings";
import Button from "../components/core/button";
import PaymentField from "../components/payment-field";
import SubscriptionCard from "../components/subscription-card";
import StripeService from "../services/stripe";
import { PaymentMethodResult, StripeSubscription } from "../types/types";
import asyncTimeout from "../utils/async-timeout";
import errorPopupParser from "../utils/error-popup-parser";
import findActiveSubscription from "../utils/find-active-subscription";
import useGlobalState from "../utils/use-global-state";
import Loading from "./loading";
import Ribbon from "../assets/icons/ribbon";

function SubscriptionSettings() {
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(false);
	const [paymentMethods, setPaymentMethods] = useState<PaymentMethodResult>();
	const [activeSubscription, setActiveSubscription] = useState<StripeSubscription>();

	const { state, dispatch } = useGlobalState();
	const { user } = state;

	const stripe = new StripeService();

	const getSubscription = async () => {
		try {
			const subscriptionsResult = await stripe.getSubscriptions();
			setActiveSubscription(findActiveSubscription(subscriptionsResult));
		} catch (error) {
			errorPopupParser(error, dispatch);
		}
	};

	const getPaymentMethods = async () => {
		try {
			const paymentMethodsResult = await stripe.getPaymentMethods();
			setPaymentMethods(paymentMethodsResult);
		} catch (error) {
			errorPopupParser(error, dispatch);
		}
	};

	const getStripeDetails = async () => {
		setIsLoading(true);
		try {
			await Promise.all([getSubscription(), getPaymentMethods(), asyncTimeout(900)]);
		} catch (error) {
		} finally {
			setIsLoading(false);
		}
	};

	const renderPaymentMethod = () => {
		if (isLoading) return <div className="p-8 bg-grey-light flex justify-between animate-pulse" />;

		if (!paymentMethods?.defaultPaymentMethod) return <div className="p-6 bg-grey-light flex justify-between">No payment method found</div>;

		return (
			<div className="p-2 bg-grey-light flex justify-between items-center cursor-pointer" onClick={() => navigate("/update-payment-method")}>
				<div>
					<p className="capitalize text-[14px]">{paymentMethods.defaultPaymentMethod.type}</p>
					<p className="text-[14px] font-bold">XXXX XXXX XXXX {paymentMethods.defaultPaymentMethod.card?.last4}</p>
				</div>

				<Settings />
			</div>
		);
	};

	useEffect(() => {
		getStripeDetails();
	}, []);

	if (isLoading) return <Loading />;

	function renderSubscriptionDetails() {
		if (!user) return <></>;
		if (user.userSubscription.hasFreeAccess)
			return (
				<div className="bg-orange-base p-2 flex gap-6">
					<Ribbon />
					<div className="text-white font-chakraPetch">
						<p className="text-[18px] opacity-60 font-semibold">HTK FREE ACCESS MEMBER</p>
						<p className="text-[14px] leading-4 font-roboto opacity-90">You have been given free access to the Hard To Kill app. You can't update your subscription settings in the app, but you can reach out to the Hard To Kill team if you need changes made.</p>
					</div>
				</div>
			);
		if (user.userSubscription.paymentType === "RevenueCat" && user.userSubscription.productId !== null)
			return (
				<>
					<div className="bg-orange-base p-2 flex gap-6">
						<Ribbon />
						<div className="text-white font-chakraPetch">
							<p className="text-[18px] opacity-60 font-semibold">HTK PREMIUM MEMBER</p>
							<p className="text-[14px] leading-4 font-roboto opacity-90">You have premium access to HTK. You can manage your subscscription settings from the Mobile App.</p>
						</div>
					</div>

					<div className="p-4">
						<a className={`btn-primary mb-4`} target="_blank" href={process.env.REACT_APP_APPLE_STORE}>
							DOWNLOAD ON THE APP STORE
						</a>
						<a className={`btn-primary mb-4`} target="_blank" href={process.env.REACT_APP_PLAY_STORE}>
							DOWNLOAD ON THE PLAY STORE
						</a>
					</div>
				</>
			);
		return (
			<>
				<p className="font-bold font-chakraPetch text-[24px]">CURRENT SUBSCRIPTION</p>

				{isLoading ? (
					<div>
						<div className="p-11 bg-grey-light flex justify-between animate-pulse mb-4" />
						<div className="p-5 bg-grey-light flex justify-between animate-pulse" />
					</div>
				) : (
					<SubscriptionCard subscription={activeSubscription} />
				)}

				{activeSubscription && !activeSubscription.cancel_at_period_end ? (
					<p className="text-center mt-6 text-orange-base cursor-pointer font-medium" onClick={() => navigate("/cancel-plan")}>
						CANCEL PLAN
					</p>
				) : (
					<Button text="SUBSCRIBE TO HTK!" onClick={() => navigate("/update-plan")} className="mt-2" />
				)}
				<div className="mt-6">
					<p className="font-bold font-chakraPetch text-[20px]">PAYMENT METHOD</p>
					<p className="font-roboto text-[14px] mb-1 text-grey-text-base">Update your HTK payment method.</p>
					{renderPaymentMethod()}
				</div>

				{activeSubscription && !activeSubscription?.cancel_at_period_end && (
					<div className="px-4">
						<p className="font-bold font-chakraPetch text-[20px]">CHANGE PLANS</p>
						<p className="font-roboto text-[14px] mb-2 text-grey-text-base">Change your HTK plan to work in with your lifestyle. Select a plan from the below you want to upgrade to!</p>
						<Button text="SWAP PLANS" onClick={() => navigate("/update-plan")} className="mb-4" />
					</div>
				)}
			</>
		);
	}

	return (
		<div>
			<div className="border-b-2 border-[#BBBDC0]  flex gap-4 items-center p-4 mb-4">
				<Arrow onClick={() => navigate("/")} />
				<p className="font-bold font-chakraPetch text-[20px]">SUBSCRIPTION</p>
			</div>

			<div className="px-4 mb-6">{renderSubscriptionDetails()}</div>
		</div>
	);
}

export default SubscriptionSettings;
