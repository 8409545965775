import axios from "axios";

const Instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 12000,
});

Instance.interceptors.request.use(async (req) => {
  const token = localStorage.getItem("HTKToken");

  if (!token || !req.headers) return req;

  req.headers.Authorization = `Bearer ${token}`;

  return req;
});

export default Instance;
