import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Arrow from '../assets/icons/arrow'
import Logo from '../assets/icons/logo'
import PaymentField from '../components/payment-field'
import { PaymentMethod } from '@stripe/stripe-js'
import Button from '../components/core/button'
import errorPopupParser from '../utils/error-popup-parser'
import useGlobalState from '../utils/use-global-state'
import StripeService from '../services/stripe'
import asyncTimeout from '../utils/async-timeout'

function UpdatePaymentMethod() {
	const navigate = useNavigate()

	const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>()
	const [currentPaymentMethod, setCurrentPaymentMethod] = useState<PaymentMethod>()
	const [isLoading, setIsLoading] = useState(false)

	const stripe = new StripeService()

	const { dispatch } = useGlobalState()

	const deleteOldPaymentMethod = async () => {
		if (!currentPaymentMethod) return
		const deleteResult = await stripe.deletePaymentMethod(currentPaymentMethod.id)
		console.log(deleteResult)
	}

	const createPaymentMethod = async () => {
		if (!paymentMethod) return
		try {
			const result = await stripe.addPaymentMethod(paymentMethod.id)
			return result
		} catch (error) {
			throw error
		}
	}

	const updatePaymentDetails = async () => {
		setIsLoading(true)
		try {
			await deleteOldPaymentMethod()

			await Promise.all([createPaymentMethod(), asyncTimeout(1000)])

			dispatch({
				type: 'setSnack',
				data: {
					isOpen: true,
					message: 'Payment details successfully updated!',
					severity: 'success',
				},
			})

			setTimeout(() => {
				navigate('/subscription-settings')
			}, 3000)
		} catch (error) {
			errorPopupParser(error, dispatch)
		} finally {
			setIsLoading(false)
		}
	}

	const getPaymentMethods = async () => {
		try {
			const result = await stripe.getPaymentMethods()
			setCurrentPaymentMethod(result.defaultPaymentMethod)
		} catch (error) {
			errorPopupParser(error, dispatch)
		}
	}

	useEffect(() => {
		getPaymentMethods()
	}, [])

	return (
		<div>
			<div className='border-b-2 border-[#BBBDC0] flex gap-4 items-center justify-between p-4 mb-4'>
				<Arrow onClick={() => navigate(-1)} />
				<p className='font-bold font-chakraPetch text-[20px]'></p>
				<Logo className='w-fit' />
			</div>

			<div className='p-4 mb-12'>
				<h2 className='text-[20px] font-bold text-grey-base font-chakraPetch'>UPDATE</h2>
				<h1 className='text-[36px] font-bold leading-10 font-chakraPetch'>PAYMENT DETAILS</h1>
				<p className='font-roboto text-[14px] mb-2 text-grey-text-base'>Update your current HTK subscription payment method.</p>

				<PaymentField setPaymentMethodId={setPaymentMethod} />
			</div>

			<div className='p-4'>
				<Button text='UPDATE' onClick={updatePaymentDetails} isLoading={isLoading} />
			</div>
		</div>
	)
}

export default UpdatePaymentMethod
