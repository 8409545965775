import React, { useState } from "react";
import { FormikType, UserCreate } from "../../types/types";
import ValidationInput from "../core/validation-input";
import ShowPassword from "../../assets/icons/show-password";
import Button from "../core/button";
import CheckboxContainer from "../core/check-box-container";

type Props = {
	className?: string;
	formik: FormikType<UserCreate>;
	isDisabled: boolean;
};
const UserDetails = ({ className, formik, isDisabled }: Props) => {
	const [passwordType, setPasswordType] = useState<"password" | "text">("password");
	const [confirmPasswordType, setConfirmPasswordType] = useState<"password" | "text">("password");
	return (
		<div className={`${className}`}>
			<p className={`text-[24px] font-bold leading-6 font-chakraPetch`}>CREATE AN ACCOUNT</p>
			<p className="font-roboto text-[14px] mb-4 text-grey-text-base opacity-80">We just need a few small details to set your account up</p>

			<div className="grid grid-cols-2 gap-1">
				<ValidationInput isDisabled={isDisabled} value={formik.values.firstName} error={formik.errors.firstName} touched={formik.touched.firstName} onChange={(e) => formik.setFieldValue("firstName", e.target.value)} placeholder="FIRST NAME" />
				<ValidationInput isDisabled={isDisabled} value={formik.values.lastName} error={formik.errors.lastName} touched={formik.touched.lastName} onChange={(e) => formik.setFieldValue("lastName", e.target.value)} placeholder="LAST NAME" />
			</div>
			<ValidationInput isDisabled={isDisabled} value={formik.values.email} error={formik.errors.email} touched={formik.touched.email} onChange={(e) => formik.setFieldValue("email", e.target.value)} placeholder="EMAIL" />
			<CheckboxContainer isChecked={formik.values.hasAgreedMarketingEmail} onCheckedChange={(isChecked) => formik.setFieldValue("hasAgreedMarketingEmail", isChecked)} label={`Yes, I want to receive tips and updates from Hard To Kill Fitness.`} />
			<h1 className="text-[24px] font-bold leading-6 font-chakraPetch mt-4">SECURITY</h1>
			<p className="font-roboto text-[14px] mb-2 text-grey-text-base">Create a secure password to protect your account.</p>

			<ValidationInput
				isDisabled={isDisabled}
				value={formik.values.password}
				error={formik.errors.password}
				touched={formik.touched.password}
				onChange={(e) => formik.setFieldValue("password", e.target.value)}
				placeholder="PASSWORD"
				inputType={passwordType}
				icon={<ShowPassword onClick={() => setPasswordType(passwordType === "password" ? "text" : "password")} />}
			/>

			<ValidationInput
				isDisabled={isDisabled}
				value={formik.values.confirmPassword}
				error={formik.errors.confirmPassword}
				touched={formik.touched.confirmPassword}
				onChange={(e) => formik.setFieldValue("confirmPassword", e.target.value)}
				placeholder="CONFIRM PASSWORD"
				inputType={confirmPasswordType}
				icon={<ShowPassword onClick={() => setConfirmPasswordType(confirmPasswordType === "password" ? "text" : "password")} />}
			/>
		</div>
	);
};

export default UserDetails;
