import React from 'react'
import { useNavigate } from 'react-router-dom'
import Arrow from '../assets/icons/arrow'
import Logo from '../assets/icons/logo'
import { CancelPages } from '../pages/cancel-plan'
import Button from './core/button'

type Props = {
	setPage: (page: CancelPages) => void
}

function CancelSubscriptionWarning({ setPage }: Props) {
	const navigate = useNavigate()

	return (
		<>
			<div className='flex gap-4 items-center p-4 mb-4'>
				<Arrow onClick={() => navigate(-1)} />
				<Logo className='mx-auto w-fit ' />
			</div>

			<div className='px-4 mt-10'>
				<h2 className='text-[20px] font-bold text-grey-base font-chakraPetch'>CANCEL SUBSCRIPTION</h2>
				<h1 className='text-[36px] font-bold leading-10 font-chakraPetch mb-2'>WE WOULD HATE TO LOSE A WARRIOR</h1>
				<p className='text-[14px] text-grey-text-base opacity-80 leading-4 mb-2'>
					Our premium HTK experience allows you to access all of what HTK has to offer, for a smaller price. This includes:
				</p>
				<ul className='text-[14px] text-grey-text-base opacity-80 leading-6 mb-2 ml-5'>
					<li>Lose access to all of HTK's Training Programs</li>
					<li>Lose access to Special Limited Time Programs</li>
					<li>Lose access to HTK Fitness Challenge</li>
				</ul>
			</div>

			<div className='px-4 mt-40'>
				<Button text='KEEP SUBSCRIPTION' onClick={() => navigate('/subscription-settings')} />
				<p className='text-center mt-4 text-orange-base cursor-pointer font-medium' onClick={() => setPage('Cancel')}>
					PROCEED TO CANCEL PLAN
				</p>
			</div>
		</>
	)
}

export default CancelSubscriptionWarning
